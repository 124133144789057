import { Component, OnInit } from '@angular/core';
import { ApiHelperService } from 'src/app/core/services/api-helper/api-helper.service';
import {
  EvenResponseI,
  NgoEventInterface,
  eventContentInterface,
} from './interface/events_interface';
import { apiUrl } from 'src/app/core/services/api-helper/api_url';
import { AuthService } from 'src/app/core/services/auth/auth.service';
import { CampaignsPaginateInterface } from './interface/campaign_paginate_interace';

@Component({
  selector: 'app-campaign-list',
  templateUrl: './campaign-list.component.html',
  styleUrls: ['./campaign-list.component.scss'],
  providers: [ApiHelperService],
})
export class CampaignListComponent implements OnInit {
  static readonly routeName: string = 'campaign-list';
  data: NgoEventInterface[] = [];

  paginateData: CampaignsPaginateInterface = {
    totalPages: 0,
    currentPage: 0,
    hasNextPage: false,
    nextPage: 0,
  };

  page: number = 1;
  size: number = 2;

  constructor(
    public http: ApiHelperService<EvenResponseI>,
    public auth: AuthService
  ) {}

  ngOnInit(): void {
    this.getEvents();
  }

  updatePageNumber(newPage: number) {
    this.page = newPage;
    this.getEvents();
  }

  getEvents(): void {
    this.http.post({
      url: `${apiUrl.getEvents}?page=${this.page}&size=${this.size}`,
      data: {
        isCompleted: false,
      },
      isAuth: false,
      successCallBack: this.callback,
    });
  }

  getImage(imageList: [eventContentInterface]): string {
    if (imageList.length >= 1) {
      return 'https://' + imageList[0].url;
    }
    return 'assets/noimg.jpg';
  }
  callback = (): void => {
    const result = this.http.apiDataState.response.result;
    this.data = result.data;
    const pagiData = this.paginateData;
    pagiData.currentPage = result.currentPage;
    pagiData.hasNextPage = result.hasNextPage;
    pagiData.totalPages = result.totalPages;
    pagiData.nextPage = result.nextPage;
  };
}
