import { Component, OnInit } from '@angular/core';
import { ApiHelperService } from 'src/app/core/services/api-helper/api-helper.service';
import { HowItWorksInterface } from './interface/how_it_works_interface';
import { apiUrl } from 'src/app/core/services/api-helper/api_url';
import { DomSanitizer } from '@angular/platform-browser';
import { SanitizedUrlService } from '../../profile/top-up-wallet/service/sanitized-url.service';

@Component({
  selector: 'app-how-it-works',
  templateUrl: './how-it-works.component.html',
  styleUrls: ['./how-it-works.component.scss'],
  providers: [ApiHelperService],
})
export class HowItWorksComponent implements OnInit {
  static readonly routeName: string = 'how-it-works';
  video: any;
  videoUrl: any;

  howItWorksList: HowItWorksInterface = {
    howItWorksUrl: '',
  };

  constructor(
    public http: ApiHelperService<HowItWorksInterface>,
    private urlService: SanitizedUrlService,
    private sanitizer: DomSanitizer
  ) {}

  ngOnInit(): void {
    this.getHowItWorks();
  }
  getHowItWorks() {
    this.http.get({
      url: apiUrl.howItWorks,
      isAuth: false,
      successCallBack: this.successGetHowItWorks,
    });
  }

  successGetHowItWorks = (): void => {
    let itemResponse = this.http.apiDataState.response.result;
    this.howItWorksList = itemResponse;
    this.video = this.howItWorksList.howItWorksUrl;
    console.log('cnsk', this.video);
    this.videoUrl = '1'; // just to meet the condition
    this.urlService.sanitzedUrl = `https://${this.howItWorksList.howItWorksUrl}`;
  };

  safeUrl() {
    return this.sanitizer.bypassSecurityTrustResourceUrl(
      this.urlService.sanitzedUrl
    );
  }
}
