import { Component, OnInit, ViewChild } from '@angular/core';
import { WalletComponent } from 'src/app/core/components/wallet/wallet.component';
import { ApiHelperService } from 'src/app/core/services/api-helper/api-helper.service';
import { apiUrl } from 'src/app/core/services/api-helper/api_url';
import { AuthService } from 'src/app/core/services/auth/auth.service';
import { MockDataService } from 'src/app/core/services/mock/mock-data.service';
import { ViewCampaignService } from 'src/app/features/campaign/service/view/view-campaign.service';

@Component({
  selector: 'app-donate-now',
  templateUrl: './donate-now.component.html',
  styleUrls: ['./donate-now.component.scss'],
})
export class DonateNowComponent implements OnInit {
  mock: Function;

  totalSelectedNumber: number = 0; // price of total selected number
  inputDonateAmount: number = 0; // input Ng model
  selectedSlots: number[] = [];
  slotTaken: number[] | [] = [];

  @ViewChild(WalletComponent) walletComponent: WalletComponent;

  constructor(
    public viewService: ViewCampaignService,
    private mockService: MockDataService,
    public http: ApiHelperService<{}>,
    public auth: AuthService
  ) {
    this.mock = this.mockService.createMockData;
  }

  ngOnInit(): void {
    this.slotTaken = this.viewService.slotsTaken;
    this.viewService.getSlotTakenObservable().subscribe((data) => {
      this.slotTaken = data;
      this.updateAndRemoveTakenSlot(data);
    });
  }

  isTaken(slotNumber: number): boolean {
    let arrayHolder = [];
    arrayHolder = this.slotTaken;
    return arrayHolder.includes(slotNumber);
  }

  onClickNumber(slotNumber: number): void {
    this.viewService.getEventDetails();
    if (this.viewService.isNumberInSlotsTaken(slotNumber)) {
      return;
    }
    this.toggleSelectClass(slotNumber);
  }

  updateAndRemoveTakenSlot(slotNumbers: number[]): void {
    const dataEl = slotNumbers.forEach((numId) => {
      const element = document.getElementById(numId + '-number');
      const elClass = element.classList.contains('selected-number');
      if (elClass) {
        element.classList.remove('selected-number');
        this.calculateSelectedNumber();
      }
    });
  }

  toggleSelectClass(slotNumber: number) {
    var element = document.getElementById(slotNumber + '-number');
    element.classList.toggle('selected-number');
    this.selectedSlots.push(slotNumber);
    this.calculateSelectedNumber();
  }

  calculateSelectedNumber(): void {
    const allSelected = document.getElementsByClassName('selected-number');
    const total = allSelected.length * this.viewService.eventData.minAmount;
    this.totalSelectedNumber = total;
    this.inputDonateAmount = total;
  }

  donateNow(isAnonymous: boolean): void {
    const allSelected = document.getElementsByClassName('selected-number');
    let pickedNumber: number[] = [];
    for (let index = 0; index < allSelected.length; index++) {
      const attrValue = allSelected[index].getAttribute('slot-value');
      pickedNumber.push(parseInt(attrValue));
    }
    this.http.post({
      url: apiUrl.donateNow.replace(':uid', this.viewService.uid),
      data: {
        donatedAmount: this.inputDonateAmount,
        selectedSlots: pickedNumber,
        isAnonymous: isAnonymous, // create ka nlng ng check box for this true/false value
      },
      successCallBack: this.successCallBack,
      doneCallBack: this.doneCallback,
      failCallBack: this.failCallBack,
    });
  }

  failCallBack = (): void => {
    const allSelected = this.selectedSlots;
    allSelected.forEach((numberId) => {
      const eachEl = document.getElementById(`${numberId}-number`);
      if (eachEl.classList.contains('selected-number')) {
        eachEl.classList.remove('selected-number');
      }
    });
    this.totalSelectedNumber = 0;
    this.inputDonateAmount = 0;
    this.selectedSlots = [];
  };

  successCallBack = (): void => {
    const allSelected = this.selectedSlots;
    // allSelected.forEach((numberId) => {
    //   const eachEl = document.getElementById(`${numberId}-number`);
    //   if (eachEl.classList.contains('selected-number')) {
    //     eachEl.classList.remove('selected-number');
    //   }
    // });

    this.totalSelectedNumber = 0;
    this.inputDonateAmount = 0;
    this.selectedSlots = [];
    this.auth.getMyProfile();
  };

  doneCallback = (): void => {
    this.viewService.getEventDetails(); // getDetails function from parent
  };

  setDonationAmount(amount: number): void {
    this.inputDonateAmount = amount;
  }
}
