import { Injectable } from '@angular/core';
import { LocalStorageService } from '../local-storage/local-storage.service';
import { LocalStorageKeys } from '../../util/local_storage_keys';
import { ProfileDetailsInterface } from '../../interface/profile_i';
import { UserAccountInterface } from '../../interface/authenticantion/user_account_interface';
import { Observable, Subject } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ResponseI } from '../../interface/response_i';
import { apiUrl } from '../api-helper/api_url';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  profileDetails: ProfileDetailsInterface = {
    userAccountId: '',
    email: '',
    mobileNumber: '',
    isVerified: false,
    balance: 0,
    firstName: '',
    lastName: '',
  };
  profileSubject = new Subject<ProfileDetailsInterface>();
  baseUrl = environment.apiURL;

  constructor(
    private localStorage: LocalStorageService,
    private http: HttpClient
  ) {}

  get isUserLogin(): boolean {
    const userData = this.localStorage.get(LocalStorageKeys.account);
    const token = this.localStorage.get(LocalStorageKeys.token);
    if (userData != null && token != null) return true;
    return false;
  }

  logoutUser(): void {
    this.localStorage.remove(LocalStorageKeys.account);
    this.localStorage.remove(LocalStorageKeys.token);
    this.localStorage.remove(LocalStorageKeys.profile);
    window.location.reload();
  }

  openLoginModal(): void {
    const loginBtn = document.getElementById('login-button');
    loginBtn.click();
  }
  get userDetails(): ProfileDetailsInterface {
    return this.profileDetails;
  }

  getDataObservable(): Observable<ProfileDetailsInterface> {
    return this.profileSubject.asObservable();
  }

  setData(newData: ProfileDetailsInterface): void {
    console.log(newData, 'set data');
    this.profileDetails = newData;
    this.profileSubject.next(this.profileDetails);
  }

  getMyProfile() {
    const userAccount: UserAccountInterface = JSON.parse(
      this.localStorage.get(LocalStorageKeys.account)
    );
    const token = this.localStorage.get(LocalStorageKeys.token);
    const headerOption = {
      headers: new HttpHeaders({
        Authorization: `Bearer ${token}`,
      }),
    };
    const httpResponse = this.http.post<ResponseI<ProfileDetailsInterface>>(
      `${this.baseUrl}${apiUrl.userDetails}`,
      {
        mobileNumber: userAccount.username,
      },
      headerOption
    );
    httpResponse.subscribe((response) => {
      this.setData(response.result);
    });
  }
}
