import { Component, OnInit } from '@angular/core';
import { ViewCampaignService } from '../service/view/view-campaign.service';
import { ActivatedRoute } from '@angular/router';
import { ApiHelperService } from 'src/app/core/services/api-helper/api-helper.service';

@Component({
  selector: 'app-campaign-view',
  templateUrl: './campaign-view.component.html',
  styleUrls: ['./campaign-view.component.scss'],
  providers: [ApiHelperService],
})
export class CampaignViewComponent implements OnInit {
  static readonly routeName: string = 'campaign/:id';
  constructor(
    public viewService: ViewCampaignService,
    private route: ActivatedRoute
  ) {
    this.viewService.uid = this.route.snapshot.paramMap.get('id');
    this.viewService.getEventDetails();
  }

  ngOnInit(): void {
    console.log(this.viewService.accumulatedAmount, 'main');
  }

  goToDonate(): void {
    const donoteNowEl = document.getElementById('ngb-nav-2');
    donoteNowEl.click();
    donoteNowEl.scrollIntoView({ behavior: 'smooth' });
  }
}
