<form [formGroup]="resetForm2" (ngSubmit)="submitResetForm2()">
  <div class="col-md-10 col-lg-10 col-12 mx-auto">
    <div class="mb-4 reset">
      <label class="resetLabel"> Reset your password</label><br />
      <span>Your password should be atleast 10 characters.</span>
    </div>
    <div class="mb-4">
      <label for="code" class="form-label">Code</label>
      <input
        type="text"
        formControlName="code"
        class="form-control input-design"
        [ngClass]="{
          'is-invalid':
            resetFormCtrl2('code').errors && resetFormCtrl2('code').touched
        }"
        id="code"
      />
      <div
        class="invalid-feedback"
        *ngIf="resetFormCtrl2('code').hasError('required')"
      >
        This field is required!
      </div>
    </div>
    <div class="mb-4">
      <label for="newPassword" class="form-label">New Password</label>
      <input
        type="password"
        formControlName="newPassword"
        class="form-control input-design"
        [ngClass]="{
          'is-invalid':
            resetFormCtrl2('newPassword').errors &&
            resetFormCtrl2('newPassword').touched
        }"
        id="newPassword"
        autocomplete="off"
      />
      <div class="invalid-feedback" *ngIf="resetFormCtrl2('newPassword')">
        <p *ngIf="resetFormCtrl2('newPassword').hasError('required')">
          This field is required!
        </p>
        <p *ngIf="resetFormCtrl2('newPassword').hasError('pattern')">
          Atleast 10 characters consist of numbers, uppercase and lowercase
          letters!
        </p>
      </div>
    </div>
    <div class="mb-4">
      <label for="confirmPassword" class="form-label">Confirm Password</label>
      <input
        type="password"
        formControlName="confirmPassword"
        class="form-control input-design"
        [ngClass]="{
          'is-invalid':
            resetFormCtrl2('confirmPassword').errors &&
            resetFormCtrl2('confirmPassword').touched
        }"
        id="confirmPassword"
        autocomplete="off"
      />
      <div class="invalid-feedback" *ngIf="resetFormCtrl2('confirmPassword')">
        <p *ngIf="resetFormCtrl2('confirmPassword').hasError('required')">
          This field is required!
        </p>
        <p
          *ngIf="resetFormCtrl2('confirmPassword').hasError('passwordMismatch')"
        >
          Password dont match!
        </p>
      </div>
    </div>
    <div class="mb-4 text-start">
      <label class="success" *ngIf="success"
        >You have successfully changed your password!</label
      >
    </div>
  </div>
  <!-- /.col -->
  <div class="col-12">
    <app-dot-loading-handler
      [dotCount]="3"
      [dataElement]="myBtn"
      [isLoading]="resetState2.isLoading"
    ></app-dot-loading-handler>
    <ng-template #myBtn let-data="data">
      <div class="d-flex">
        <button
          *ngIf="!success"
          class="btn orange-btn f-weight-6 align-self-center mx-auto btn-med"
          type="submit"
          [disabled]="!resetForm2.valid"
        >
          RESET
        </button>
        <a
          *ngIf="success"
          class="btn orange-btn f-weight-6 align-self-center mx-auto btn-med"
          href="#"
          role="button"
          data-bs-toggle="modal"
          data-bs-target="#loginRegister"
        >
          Go to Login
        </a>
      </div>
    </ng-template>
  </div>
</form>
