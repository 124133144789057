import { Component, OnInit } from '@angular/core';
import { ViewCampaignService } from '../../../service/view/view-campaign.service';

@Component({
  selector: 'app-tabs',
  templateUrl: './tabs.component.html',
  styleUrls: ['./tabs.component.scss'],
})
export class TabsComponent implements OnInit {
  active = 1;
  htmlContent:string;
  constructor(public viewService: ViewCampaignService) {
    console.log(this.viewService.eventData.details, 'details');

  }

  ngOnInit(): void {
    console.log(this.viewService.eventData.details,'details');
    this.htmlContent = this.viewService.eventData.details;
  }
}
