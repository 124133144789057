<div>
  <ng-container *ngIf="!editMode; else editModeTemplate">
    <h2 class="mt-5 white-text theme-text fw-semibold">
      {{ item.firstName }} {{ item.lastName }}
    </h2>
    <p class="white-text theme-text">{{ item.mobileNumber }}</p>
    <p class="white-tex theme-text">{{ item.email }}</p>

    <!-- Edit button -->
    <button
      class="btn orange-btn f-weight-6 align-self-center mx-auto px-5 text-capitalize"
      (click)="toggleEditMode()"
    >
      Edit Info
    </button>
  </ng-container>

  <ng-template #editModeTemplate>
    <!-- Edit form -->
    <form [formGroup]="updateUserDetailsForm" (ngSubmit)="updateUserDetails()">
      <div class="row mt-5">
        <div class="col-md-6">
          <div class="mb-3">
            <label for="firstName" class="form-label">First Name</label>
            <input
              type="text"
              id="firstName"
              class="form-control input-design-3"
              [ngClass]="{
                'is-invalid':
                  userDetailsFormCtrl('firstName').errors &&
                  userDetailsFormCtrl('firstName').touched
              }"
              formControlName="firstName"
            />
            <div
              class="invalid-feedback"
              *ngIf="userDetailsFormCtrl('firstName').hasError('required')"
            >
              This field is required!
            </div>
          </div>
        </div>
        <!-- /.firstName -->
        <div class="col-md-6">
          <div class="mb-3">
            <label for="lastName" class="form-label">Last Name</label>
            <input
              type="text"
              id="lastName"
              class="form-control input-design-3"
              [ngClass]="{
                'is-invalid':
                  userDetailsFormCtrl('lastName').errors &&
                  userDetailsFormCtrl('lastName').touched
              }"
              formControlName="lastName"
            />
            <div
              class="invalid-feedback"
              *ngIf="userDetailsFormCtrl('lastName').hasError('required')"
            >
              This field is required!
            </div>
          </div>
        </div>
        <!-- /.lastName-->
        <div class="col-md-6">
          <div class="">
            <label for="mobileNumber" class="form-label">Phone Number</label>
            <input
              type="text"
              id="mobileNumber"
              class="form-control input-design-3"
              [ngClass]="{
                'is-invalid':
                  userDetailsFormCtrl('mobileNumber').errors &&
                  userDetailsFormCtrl('mobileNumber').touched
              }"
              formControlName="mobileNumber"
            />
            <div
              class="invalid-feedback"
              *ngIf="userDetailsFormCtrl('mobileNumber')"
            >
              <p
                *ngIf="userDetailsFormCtrl('mobileNumber').hasError('required')"
              >
                This field is required!
              </p>
              <p
                *ngIf="userDetailsFormCtrl('mobileNumber').hasError('pattern')"
              >
                Invalid phone number!
              </p>
            </div>
            <p class="text-start">
              <i class="fa fa-exclamation-circle"></i>
              <i class="theme-text f-sm black-text"
                >change your mobile number will not change your username</i
              >
            </p>
          </div>
        </div>
        <!-- /.phoneNumber-->
        <div class="col-md-6">
          <div class="">
            <label for="email" class="form-label">Email</label>
            <input
              type="text"
              id="email"
              class="form-control input-design-3"
              [ngClass]="{
                'is-invalid':
                  userDetailsFormCtrl('email').errors &&
                  userDetailsFormCtrl('email').touched
              }"
              formControlName="email"
            />
            <div class="invalid-feedback" *ngIf="userDetailsFormCtrl('email')">
              <p *ngIf="userDetailsFormCtrl('email').hasError('required')">
                This field is required!
              </p>
              <p *ngIf="userDetailsFormCtrl('email').hasError('email')">
                Invalid email !
              </p>
            </div>
          </div>
        </div>
        <!-- /.email-->
      </div>
      <div class="row justify-content-center mt-5">
        <div class="col-md-4">
          <div class="d-flex justify-content-center">
            <button
              class="btn orange-btn f-weight-6 my-btn-sm mx-3"
              type="submit"
              [disabled]="!updateUserDetailsForm.valid"
            >
              Save
            </button>
            <button
              class="btn btn-outline-secondary theme-text-bold my-btn-sm mx-3"
              type="cancel"
              (click)="toggleEditMode()"
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
    </form>
  </ng-template>
</div>
