import { Component, OnInit } from '@angular/core';
import { FaqInterface } from './interface/faq_interface';
import { ApiHelperService } from 'src/app/core/services/api-helper/api-helper.service';
import { apiUrl } from 'src/app/core/services/api-helper/api_url';

@Component({
  selector: 'app-faq',
  templateUrl: './faq.component.html',
  styleUrls: ['./faq.component.scss'],
  providers: [ApiHelperService],
})
export class FaqComponent implements OnInit {
  static readonly routeName: string = 'faq';
  faqsList: FaqInterface[] = [];

  constructor(private http: ApiHelperService<FaqInterface[]>) {}

  ngOnInit(): void {
    this.getFaq();
  }
  getFaq() {
    this.http.get({
      url: apiUrl.faqs,
      isAuth: false,
      successCallBack: this.successGetFaq,
    });
  }
  successGetFaq = (): void => {
    let res = this.http.apiDataState;
    this.faqsList.push(...res.response.result);
  };

  public get size(): number {
    return this.faqsList.length;
  }
}
