import { Component, Input, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, Validators } from '@angular/forms';
import { ApiHelperService } from '../../services/api-helper/api-helper.service';
import { apiUrl } from '../../services/api-helper/api_url';
import { CustomValidationService } from 'src/app/core/services/custom_validation/custom-validation.service';
import { RegisterFormResponseInterface } from './interface/register_form_response_interface';
import { MainModalContainerService } from '../main-modal-container/service/main-modal-container.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-register-form',
  templateUrl: './register-form.component.html',
  styleUrls: ['./register-form.component.scss']
})
export class RegisterFormComponent implements OnInit {
  @Input() modal;
  isLoading: boolean = false;

  // form input builder
  registerForm = this.formBuilder.group(
    {
      firstName: ['', Validators.required],
      lastName: ['', Validators.required],
      mobileNumber: [
        '',
        [Validators.required, Validators.pattern(/^[0-9]{11}$/)],
      ],
      email: ['', [Validators.required, Validators.email]],
      confirmEmail: ['', Validators.required],
      password: [
        '',
        [
          Validators.required,
          Validators.pattern(
            /^(?=\D*\d)(?=[^a-z]*[a-z])(?=[^A-Z]*[A-Z]).{8,30}$/
          ),
        ],
      ],
      confirmPassword: ['', Validators.required],
    },
    {
      validator: [
        this.customValidator.passwordMatchValidator('password', 'confirmPassword'),
        this.customValidator.emailMatchValidator('email', 'confirmEmail'),
      ]
    }
  );

  
  constructor(
    private formBuilder: FormBuilder,
    private http: ApiHelperService<RegisterFormResponseInterface>,
    private customValidator: CustomValidationService,
    public mainModalService: MainModalContainerService,
    private modalService: NgbModal
  ) {
    this.isLoading = this.http.apiDataState.isLoading;
  }

  ngOnInit(): void {}

  get registerControl() {
    return this.registerForm.controls;
  }
  
  registerFormCtrl(name: string): AbstractControl {
    return this.registerForm.get(name);
  }

  openLoginModal(content: any) {
    this.modalService.open(content, { centered: true, size: 'lg' });
  }

  submitRegisterForm() {
    this.http.post({
      url: apiUrl.registerUser,
      data: this.registerForm.value,
      isAuth: false,
      successCallBack: this.callback,
    });
  }

  callback = (): void => {
    let response = this.http.apiDataState.response;
    this.registerForm.reset();
  }
} 
