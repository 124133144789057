<nav class="navbar navbar-expand-lg navbar-light bg-light">
  <div class="container">
    <a
      class="navbar-brand"
      href="https://www.facebook.com/heto.ph?mibextid=LQQJ4d"
      ><img class="img-fluid" id="nav-icon" src="/assets/Hand.png"
    /></a>
    <button
      class="navbar-toggler"
      type="button"
      data-toggle="collapse"
      data-target="#navbarSupportedContent"
      aria-controls="navbarSupportedContent"
      aria-expanded="false"
      aria-label="Toggle navigation"
    >
      <span class="navbar-toggler-icon"></span>
    </button>

    <div
      class="collapse navbar-collapse flex-row-reverse"
      id="navbarSupportedContent"
      #navbar
    >
      <ul class="navbar-nav">
        <li class="nav-item" (click)="collapseNavbar()">
          <a class="nav-link" appRouteName="home">{{ routes.home.title }}</a>
        </li>
        <li class="nav-item" (click)="collapseNavbar()">
          <a class="nav-link" appRouteName="ourCampaign">{{
            routes.campaignList.title
          }}</a>
        </li>
        <li class="nav-item" (click)="collapseNavbar()">
          <a class="nav-link" appRouteName="aboutUs">{{
            routes.aboutUs.title
          }}</a>
        </li>

        <li class="nav-item dropdown">
          <a
            class="nav-link dropdown-toggle"
            id="navbarDropdown"
            role="button"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
          >
            FAQ's
          </a>
          <div class="dropdown-menu" aria-labelledby="navbarDropdown">
            <a
              class="dropdown-item"
              appRouteName="faq"
              (click)="collapseNavbar()"
              >{{ routes.faq.title }}</a
            >
            <a
              class="dropdown-item"
              appRouteName="howItWorks"
              (click)="collapseNavbar()"
              >{{ routes.howItWorks.title }}</a
            >
          </div>
        </li>

        <li class="nav-item" (click)="collapseNavbar()">
          <a class="nav-link" appRouteName="contactUs">{{
            routes.contactUs.title
          }}</a>
        </li>

        <li class="nav-item dropdown">
          <a
            class="nav-link "
            id="navbarDropdown"
            role="button"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
            *ngIf="auth.isUserLogin; else elseNotLogin"
          >
            <span class="d-md-none d-sm-block dropdown-toggle"> Profile</span>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              class="bi bi-person-circle custom-icon-color d-none d-xs-none d-md-block"
              viewBox="0 0 16 16"
            >
              <path d="M11 6a3 3 0 1 1-6 0 3 3 0 0 1 6 0z" />
              <path
                fill-rule="evenodd"
                d="M0 8a8 8 0 1 1 16 0A8 8 0 0 1 0 8zm8-7a7 7 0 0 0-5.468 11.37C3.242 11.226 4.805 10 8 10s4.757 1.225 5.468 2.37A7 7 0 0 0 8 1z"
              />
            </svg>
          </a>
          <div class="dropdown-menu" aria-labelledby="navbarDropdown">
            <a
              class="dropdown-item"
              appRouteName="topUpWallet"
              (click)="collapseNavbar()"
              >{{ routes.topUpWallet.title }}</a
            >
            <a
              class="dropdown-item"
              appRouteName="myAccount"
              (click)="collapseNavbar()"
              >{{ routes.myAccount.title }}</a
            >
            <a class="dropdown-item" (click)="openLogoutModal(content)"
              >LOGOUT</a
            >
          </div>
        </li>

        <ng-template #elseNotLogin>
          <li class="nav-item">
            <a
              role="button"
              id="login-button"
              class="nav-link mx-0"
              (click)="openLoginModal(content)"
              >Login</a
            >
          </li>
        </ng-template>
        <li class="nav-item">
          <a class="nav-link"></a>
        </li>
        <!-- /. leave this li as blank -->
      </ul>
    </div>
  </div>
</nav>

<ng-container *ngIf="auth.isUserLogin">
  <app-wallet class="mb-3"></app-wallet>
</ng-container>
<div class="space-h-med"></div>
<div class="space-h-med"></div>

<ng-template #content let-modal>
  <app-main-modal-container [modal]="modal"></app-main-modal-container>
</ng-template>
