import { Component, OnInit } from '@angular/core';
import { Validators, FormBuilder, AbstractControl } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { ApiHelperService } from 'src/app/core/services/api-helper/api-helper.service';
import { apiUrl } from 'src/app/core/services/api-helper/api_url';
import { CustomValidationService } from 'src/app/core/services/custom_validation/custom-validation.service';
import { ChangePasswordResponseInterface } from './interface/change_password_response_interface';
import { ResponseI } from 'src/app/core/interface/response_i';
import { StateI } from 'src/app/core/interface/state_i';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss'],
})
export class ChangePasswordComponent implements OnInit {
  uid: any;

  // state hanlder
  changePasswordState: StateI<
    ResponseI<ChangePasswordResponseInterface>
  > = {
    isLoading: false,
    isFail: false,
    response: <ResponseI<ChangePasswordResponseInterface>>{
      timestamp: '',
      status: 0,
      message: '',
      path: '',
      result: <ChangePasswordResponseInterface>{},
    },
    isSuccess: false,
  };

  // form input builder
  changePasswordForm = this.formBuilder.group(
    {
      oldPassword: ['', Validators.required],
      newPassword: [
        '',
        [
          Validators.required,
          Validators.pattern(
            /^(?=\D*\d)(?=[^a-z]*[a-z])(?=[^A-Z]*[A-Z]).{8,30}$/
          ),
        ],
      ],
      confirmPassword: ['', Validators.required],
    },
    {
      validator: this.customValidator.passwordMatchValidator2(
        'newPassword',
        'confirmPassword'
      ),
    }
  );

  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private http: ApiHelperService<ChangePasswordResponseInterface>,
    private route: ActivatedRoute,
    private customValidator: CustomValidationService
  ) {
    /// init the state
    this.changePasswordState = this.http.apiDataState;
  }

  ngOnInit(): void {
    this.uid = this.route.snapshot.paramMap.get('userAccountId');
  }

  setLoading(isLoading: boolean) {
    this.changePasswordState.isLoading = isLoading;
  }

  get changePasswordControl() {
    return this.changePasswordForm.controls;
  }

  changePasswordFormCtrl(name: string): AbstractControl {
    return this.changePasswordForm.get(name);
  }

  submitChangePasswordForm() {
    this.http.put({
      url: apiUrl.changePassword,
      data: this.changePasswordForm.value,
      successCallBack: this.callback,
    });
  }

  callback = (): void => {
    let response = this.changePasswordState;
    console.log(response);
    this.changePasswordForm.reset();
  };
}
