<div class="container">
  <div class="row mb-3">
    <div class="col-12" *ngIf="videoUrl != null">
      <p class="text-start f-lg theme-text-bold my-4">Demo</p>
      <iframe
        id="video1"
        [src]="safeUrl()"
        frameborder="0"
        class="best-card best-card-shadow"
      ></iframe>
    </div>
    <!-- /.col -->
  </div>
</div>
