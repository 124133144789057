<div class="row mt-5">
  <div class="col-md-4 col-12">
    <div class="profile-container">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="50"
        height="50"
        fill="currentColor"
        class="bi bi-person-bounding-box"
        viewBox="0 0 16 16"
      >
        <path
          d="M1.5 1a.5.5 0 0 0-.5.5v3a.5.5 0 0 1-1 0v-3A1.5 1.5 0 0 1 1.5 0h3a.5.5 0 0 1 0 1zM11 .5a.5.5 0 0 1 .5-.5h3A1.5 1.5 0 0 1 16 1.5v3a.5.5 0 0 1-1 0v-3a.5.5 0 0 0-.5-.5h-3a.5.5 0 0 1-.5-.5M.5 11a.5.5 0 0 1 .5.5v3a.5.5 0 0 0 .5.5h3a.5.5 0 0 1 0 1h-3A1.5 1.5 0 0 1 0 14.5v-3a.5.5 0 0 1 .5-.5m15 0a.5.5 0 0 1 .5.5v3a1.5 1.5 0 0 1-1.5 1.5h-3a.5.5 0 0 1 0-1h3a.5.5 0 0 0 .5-.5v-3a.5.5 0 0 1 .5-.5"
        />
        <path
          d="M3 14s-1 0-1-1 1-4 6-4 6 3 6 4-1 1-1 1zm8-9a3 3 0 1 1-6 0 3 3 0 0 1 6 0"
        />
      </svg>
    </div>
  </div>
  <div class="col-md-8 col-12">
    <app-view-user-details></app-view-user-details>
  </div>
</div>
<div class="mt-5">
  <div class=" col-12"
      class="nav-link border border-0 p-0 text-center my-tab"
      id="change-password-tab"
      type="button"
    >
      MY ACCOUNT
      <hr class="hr-1"  />
    </div>
    <app-change-password></app-change-password>
</div>

<!-- <div class="row mt-5 w-100">
  <div class="col-12 col-md-6">
    <div
      class="nav-link border border-0 p-0 text-center my-tab"
      id="change-password-tab"
      type="button"
      (click)="isShowChangePass = true"
      [ngClass]="{ active: isShowChangePass }"
    >
      MY ACCOUNT
      <hr class="hr-1" *ngIf="!isShowChangePass; else isShowPass" />
    </div>
  </div>
  <div class="col-12 col-md-6">
    <div
      class="nav-link border border-0 p-0 text-center my-tab"
      id="my-joined-events-tab"
      type="button"
      role="tab"
      (click)="isShowChangePass = false"
      [ngClass]="{ active: !isShowChangePass }"
    >
      DONATIONS

      <hr class="hr-1" *ngIf="isShowChangePass; else isShowPass" />
    </div>
  </div>
  <div class="col-12">
    <ng-container *ngIf="isShowChangePass">
      <app-change-password></app-change-password>
    </ng-container>
    <ng-container *ngIf="!isShowChangePass">
      <app-my-joined-events></app-my-joined-events>
    </ng-container>
  </div>
</div>
<ng-template #isShowPass>
  <hr class="hr-1 active" />
</ng-template> -->

