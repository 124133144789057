<div class="footer mt-4">
  <footer
    class="d-flex flex-wrap justify-content-between align-items-center py-3 container"
  >
    <div class="col-md-4 col-12 text-center">
      <p class="mb-0">
        <a class="mb-0 white-text" role="button" (click)="openLoginModal(terms)"
          >Terms & Conditions
        </a>
      </p>
      <p class="mb-0">
        <a
          class="mb-0 white-text"
          role="button"
          (click)="openLoginModal(privacy)"
          >Privacy Policy
        </a>
      </p>
    </div>
    <div class="col-md-4 col-12 my-5 text-center">
      <p class="mb-0">Follow us on:</p>
      <a href="https://www.facebook.com/heto.ph?mibextid=LQQJ4d">
        <i class="fa fa-facebook-square footer-icon" aria-hidden="true"></i>
      </a>
      <i class="fa fa-youtube-play footer-icon" aria-hidden="true"></i>
      <i class="fa fa-instagram footer-icon"></i>
      <i class="fa fa-linkedin-square footer-icon"></i>
      <div class="label">
        <span class="power">Powered by BEST Inc.</span>
        <img class="logo" src="/assets/FooterLogoGreen.png"/>
    </div>
    </div>

    <div class="col-md-4 col-12 text-center">
      <a href="#" class="link mb-0 text-decoration-none"
        >5th floor, Phinma Plaza, 39 Plaza Drive,<br />
        Rockwell Center Makati City</a
      ><br />
      <a href="mailto:support@heto.ph" class="link mb-0 text-decoration-none"
        >support@heto.ph
      </a>
    </div>
  </footer>
</div>
<ng-template #terms let-modal>
  <app-terms-and-conditions [modal]="modal"></app-terms-and-conditions>
</ng-template>
<ng-template #privacy let-modal>
  <app-privacy-policy [modal]="modal"></app-privacy-policy>
</ng-template>
