<div class="modal-header border-0">
  <button
    type="button"
    class="close"
    aria-label="Close"
    (click)="modal.dismiss('Cross click')"
  >
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <div class="row mb-3 container">
    <div class="col-12">
      <p class="title"><u>HETO – Privacy Policy</u></p>
      <br />
      <ol>
        <li>
          <b>INTRODUCTION</b>
          <p>
            Welcome to HETO. HETO values privacy and complies with all relevant
            privacy laws. Our goal is to respect the privacy of all users who
            access our platform through the website or mobile application and
            use our services. "Users" refers to individuals who create an
            account with us for access to our services. We understand the
            significance of the personal information you provide to us and
            believe it is our responsibility to properly manage, protect, and
            process it. This Privacy Policy outlines how we collect, use,
            disclose, and process your personal information, as well as helps
            you make an informed decision before sharing any personal
            information with us.
          </p>
          <p>
            “Personal information” or “personal data” refers to data that can
            identify an individual, such as name and contact information.
          </p>
          <p>
            By using our services, registering for an account, visiting our
            platform, or accessing our services, you acknowledge and agree to
            the practices, requirements, and policies outlined in this Privacy
            Policy. If you do not consent to the processing of your personal
            information as described in this policy, please do not use our
            services or access our platform. We reserve the right to change this
            Privacy Policy at any time and will notify you of any changes. Your
            continued use of our services or platform constitutes your
            acceptance of the changes made to this Privacy Policy.
          </p>
          <p>
            This Privacy Policy works alongside any other notices, contractual
            clauses, or consent clauses that apply to the collection, storage,
            use, disclosure, and processing of your personal information by us.
          </p>
          <p>
            This policy applies to both buyers and sellers using our services,
            unless stated otherwise.
          </p>
        </li>
        <li>
          <b>WHEN WILL HETO COLLECT PERSONAL INFORMATION?</b>
          <p>
            We may collect personal information from you in the following
            instances:
          </p>
          <ul>
            <li>
              When you register to use our Services or Platform or open an
              account with us
            </li>
            <li>
              When you provide documentation or information regarding your
              interactions with us, or when you use our products and services
            </li>
            <li>
              When you communicate with us through methods such as telephone
              calls (which may be recorded), letters, fax, face-to-face
              meetings, social media platforms, or emails, including when you
              interact with our customer service representatives
            </li>
            <li>
              When you use our electronic services or interact with our
              application or services on our Platform, including through the use
              of cookies
            </li>
            <li>
              When you grant permission on your device to share information with
              our application or Platform
            </li>
            <li>
              When you link your HETO account with your social media or external
              account, or use other social media features in accordance with the
              provider’s policies
            </li>
            <li>When you carry out transactions through our Services</li>
            <li>When you provide us with feedback or complaints</li>
            <li>
              When you submit any personal information to us for any other
              reason
            </li>
            <li>
              Note: This list is not exhaustive and provides examples of common
              instances in which we may collect personal information about you.
            </li>
          </ul>
        </li>
        <br />
        <li>
          <b>WHICH PERSONAL INFORMATION WILL HETO COLLECT?</b>
          <p>HETO may collect the following personal information:</p>
          <ul>
            <li>Name;</li>
            <li>Email address;</li>
            <li>Date of birth;</li>
            <li>Billing and shipping address;</li>
            <li>Bank account and payment details;</li>
            <li>Telephone number;</li>
            <li>Gender;</li>
            <li>
              Information related to the device used to access our services or
              platform;
            </li>
            <li>
              Information regarding your network and the people and accounts you
              interact with;
            </li>
            <li>Photographs, audio or video recordings;</li>
            <li>
              Government-issued identification or other information required for
              identity verification, know-your-customer, and fraud prevention
              purposes;
            </li>
            <li>
              Marketing and communication information, such as preferences for
              receiving marketing materials and communication history;
            </li>
            <li>
              Usage and transaction data, including search history, order
              details, and information about the advertising and content you
              interact with on the platform;
            </li>
            <li>Location data;</li>
            <li>
              Any other information provided when using our services or
              platform; and
            </li>
            <li>Aggregated data on the content you engage with.</li>
          </ul>
          <br />
          <p>
            You must not provide any false or misleading information, and you
            are required to inform us of any inaccuracies or changes to the
            information provided. We reserve the right to request additional
            documentation to verify the information.<br /><br />
            If you sign up for our platform using your social media account,
            link your HETO account with it, or use any HETO social media
            features, we may access information you have voluntarily provided to
            the social media provider. We will manage and use such personal
            information in accordance with this policy.<br /><br />
            If you do not want us to collect this information, you may opt out
            at any time by informing our Data Protection Officer in writing by
            emailing us at <a href="mailto:support@heto.ph">support@heto.ph</a>.
            However, opting out or withdrawing your consent for collecting,
            using, or processing your personal information may affect your use
            of our services and platform.
          </p>
        </li>
        <li>
          <b>COLLECTION OF ADDITIONAL INFORMATION</b>
          <p>
            When you browse the HETO platform, information is logged by the web
            server, which includes data about you. This typically includes your
            device's IP address, operating system and browser type, mobile
            device type and identifier, referring website (if any), pages
            visited on the site and the time of visit, and a cookie to remember
            your last visit. If you are logged in, this information will be
            linked to your personal account and included in anonymous statistics
            to understand site usage.
          </p>
          <p>
            Our mobile apps may use technologies like GPS and Wi-Fi to collect
            precise information about the location of your mobile device. This
            information is used for purposes such as location-based services,
            delivering relevant content based on your location, or allowing you
            to share your location with other users. You can withdraw permission
            for collecting this information through your device settings. For
            questions on disabling location services, please contact your mobile
            device service provider or manufacturer.
          </p>
          <p>
            Similar information is sent to HETO when you view content,
            advertisements, and access software on the platform or through the
            services, including your IP address, operating system, etc. Instead
            of page views, the device sends information about the content,
            advertisement viewed and/or software installed by the platform and
            the time.
          </p>
        </li>
        <li>
          <b>COOKIES</b>
          <p>
            We or our authorized service providers and advertising partners may,
            from time to time, make use of "cookies" or other features to gather
            or share information related to your use of our Services or
            Platform. This is done to improve our Platform and the Services we
            offer, introduce new services and features, and/or help us and our
            advertising partners deliver content that is more relevant to you,
            including through remarketing. "Cookies" are identifiers stored on
            your computer or mobile device that record information such as the
            information of your computer or device, how and when you use or
            visit the Services or Platform, the number of people using them, and
            other activities within our Platform. This information can be linked
            to personal data, and cookies also associate with information about
            the web pages you have viewed. This data is used by our third-party
            advertising partners to display advertisements on websites across
            the internet, and to analyze data and monitor the usage of the
            Services.
          </p>
          <p>
            You have the option to refuse the use of cookies by adjusting the
            settings on your browser or device. However, please note that this
            may prevent you from fully accessing the functionality of our
            Platform or the Services.
          </p>
        </li>
        <li>
          <b>INFORMATION STORAGE AND USAGE</b>
          <p>
            Your personal information may be collected, used, disclosed, and
            processed for the following reasons:
          </p>
          <ul>
            <li>
              To evaluate and process your applications or transactions with us
              or with third parties through the services;
            </li>
            <li>
              To manage, provide, and administer access to our services and
              platform;
            </li>
            <li>
              To handle and complete transactions, respond to requests for
              products and services, and keep you informed about service issues
              and unusual account activity;
            </li>
            <li>
              To enforce our Terms of Service or any relevant end-user license
              agreements;
            </li>
            <li>
              To protect personal safety and the rights, property, or safety of
              others;
            </li>
            <li>
              For verification, due diligence, or know-your-customer purposes;
            </li>
            <li>
              To assess and make decisions about your credit and risk profile
              and eligibility for credit products;
            </li>
            <li>
              To maintain and update software to ensure the smooth functioning
              of our services;
            </li>
            <li>
              To handle customer service inquiries, carry out instructions, and
              respond to any questions;
            </li>
            <li>
              To communicate with you via voice calls, text messages, fax
              messages, email, or postal mail regarding the administration and
              management of your relationship with us and use of our services;
            </li>
            <li>
              To allow other users to interact with you or see some of your
              activities on the platform, including informing you when someone
              has sent you a message or connected with you; • To conduct
              research, analysis, and development, improve our services, and
              enhance the customer experience;
            </li>
            <li>
              For marketing and advertising purposes, sending you information
              and materials about products and services through various mediums
              of communication;
            </li>
            <li>
              To comply with legal processes or any applicable laws and
              regulations;
            </li>
            <li>For internal and statutory reporting and record-keeping;</li>
            <li>
              To carry out screening activities in accordance with legal
              obligations or our risk management procedures;
            </li>
            <li>
              To prevent or investigate violations of our Terms of Service,
              fraud, or any other misconduct;
            </li>
            <li>To respond to claims or violations of third-party rights;</li>
            <li>
              To store, host, and backup your personal information, both within
              and outside your jurisdiction;
            </li>
            <li>To deal with business asset transactions;</li>
          </ul>
          <br />
          <p>
            Any other purposes that we may notify you of when obtaining your
            consent. (Collectively referred to as the "Purposes"). You agree
            that HETO may access, preserve, and disclose your account
            information and content if required to do so by law, court order, or
            in good faith belief that such disclosure is necessary to comply
            with legal processes, protect the rights, property, or safety of
            HETO, its users, or the public.
          </p>
        </li>
        <li>
          <b>HOW DOES HETO SAFEGUARD AND MAINTAIN CUSTOMER INFORMATION?</b>
          <p>
            To ensure the safety of your personal information, we employ various
            security measures and constantly strive to secure our systems.
            Access to personal information is restricted to a select few
            employees with special clearance to the secured networks where this
            information is stored. Although we make every effort to maintain
            security, absolute security cannot be guaranteed.
          </p>
          <p>
            We comply with privacy laws and retain personal information
            according to their regulations. When it is no longer necessary for
            us to retain the information for the purpose it was collected, for
            legal or business purposes, or there are no longer any legitimate
            reasons to keep it, we will either destroy or anonymize it. In the
            case that you stop using our platform or your permission to use it
            and the services we offer is revoked, we may still keep and use your
            personal information according to our obligations under privacy laws
            and this privacy policy. We may dispose of your information securely
            without prior notice, subject to applicable laws.
          </p>
        </li>
        <li>
          <b>DISCLOSURE OF INFORMATION</b>
          <p>
            HETO may need to share the information they collect from visitors
            with third-party service providers, agents, affiliates, and other
            third parties. These third parties may be located in or outside of
            the Philippines and may process the data on behalf of HETO for the
            stated purposes. The third parties are required to maintain the
            security and confidentiality of the data and only retain it for as
            long as it's necessary. This can include subsidiaries, contractors,
            regulatory authorities, and parties involved in a merger or sale of
            assets. HETO may also share demographic and statistical information
            about their users with advertising partners and third-party
            suppliers.
          </p>
          <p>
            The company may use personal data for legal reasons such as
            complying with legal obligations, performing their contract with the
            user, or in connection with a legal claim. However, there can be no
            guarantee of complete security for the data transmitted on the site
            as it may be vulnerable to unauthorized access or misuse.
          </p>
          <p>
            Users who receive personal data from HETO through the use of the
            services must comply with all applicable privacy laws and not use
            the information for anything other than responding to inquiries and
            carrying out transactions with the written consent of the user and
            HETO. The Receiving Party must also allow HETO or the Disclosing
            Party to remove their data and review the information collected
            about them.
          </p>
        </li>
        <li>
          <b>INFORMATION COLLECTED BY THIRD PARTIES</b>
          <p>
            Our Platform uses various analytical tools to help us understand how
            Users use our Services. These tools may collect information about
            your use of the Platform through the use of cookies, including your
            IP address, and transmit this information to servers for storage and
            analysis. The information may be used for the purpose of evaluating
            your use of the Platform, compiling reports on activity, and
            providing other relevant services related to website activity and
            internet usage. This information may also be shared with third
            parties if required by law, or if these third parties process the
            information on behalf of our Platform.
          </p>
          <p>
            Our Platform or Services may make software applications available
            for download from time to time. These applications may access your
            identifiable information, such as your name and user ID, and may
            also require additional information from you directly. These
            third-party products or services are not controlled by HETO and we
            advise you to review the terms and policies of these third parties
            before using their applications.
          </p>
        </li>
        <li>
          <b>DISCLAIMER OF LIABILITY FOR THIRD PARTY SITES AND SECURITY</b>
          <p>
            WE CANNOT GUARANTEE THE PROTECTION OF YOUR INFORMATION WHEN USING
            THIRD PARTY WEBSITES. We have taken steps to ensure the security of
            personal data we hold or control, such as implementing security
            measures and limiting access to this information to authorized
            individuals. When you access your personal data or place an order,
            we use a secure server.
          </p>
          <p>
            In an effort to provide you with more value, we may include links to
            third-party websites within our Platform or offer related services
            in partnership with other companies. These third-party websites have
            separate and independent privacy policies and security measures, and
            we have no control over their practices. Information collected by
            third-party websites, even if they are related to us, may not be
            received by us.
          </p>
          <p>
            As a result, we are not responsible for the content, security
            measures, or activities on these thirdparty websites. Accessing
            these sites is at your own risk, but we strive to maintain the
            integrity of our Platform and its links. If you have any feedback
            about these linked sites, such as a broken link, please let us know.
          </p>
        </li>
        <li>
          <b>CHANGING OR REMOVING INFORMATION YOU'VE PROVIDED TO US</b>
          <p>
            Withdrawing Consent<br />If you no longer wish for us to collect,
            use, or share your personal information, you can withdraw your
            consent by sending an email to
            <a href="mailto:support@heto.ph">support@heto.ph</a>. However,
            please note that this may result in us being unable to provide the
            Services to you and we may have to end our relationship with you.
          </p>

          <p>
            Accessing or Correcting Information<br />If you have an account with
            us, you can access and/or make changes to your personal information
            by going to the Account Settings page on the Platform. If you don't
            have an account, you can send a written request to access or correct
            your information by emailing us at
            <a href="mailto:support@heto.ph">support@heto.ph</a>. We'll need
            enough information to verify your identity and understand the nature
            of your request.
          </p>
          <p>
            Please note that in some cases, we may not be able to correct your
            information, as required by applicable laws and regulations.
          </p>
        </li>
        <li>
          <b>CONTACT US FOR QUESTIONS, CONCERNS, OR COMPLAINTS</b>
          <p>
            If you have any inquiries or worries about our privacy policies,
            please do not hesitate to reach out to us via email at
            <a href="mailto:support@heto.ph">support@heto.ph</a>.
          </p>
        </li>
      </ol>
    </div>
  </div>
</div>
<!-- dont remove it serves as spacer -->
<div class="modal-footer border-0"></div>
