<div class="container">
  <div class="row min-vh-60">
    <div class="col-lg-6 col-md-8 col-12 mx-auto">
      <p class="text-md-start text-center f-lg theme-text-bold my-4 white-text">
        Frequently Ask Questions (FAQ's)
      </p>
      <div class="ms-md-5">
        <ul class="list-group w-100" *ngFor="let faqs of faqsList">
          <li class="list-group-item question" (click)="faqs.isExpanded = !faqs.isExpanded">
            <span class="mr-3">Q.</span> {{faqs.question}}
          </li>
          <li class="list-group-item answer mb-2 expanded-panel" *ngIf="faqs.isExpanded" >
            <span class="mr-3">A.</span> {{faqs.answer}}
          </li>
        </ul>
      </div>
    </div>
  </div>
</div>
