export const apiUrl = {
  // ***********************
  // ***** post method *****
  // ***********************
  login: 'auth/login',
  registerUser: 'auth/register',
  uploadKycImages: 'auth/register/:registrationId/uploadImages',
  userDetails: 'user/accounts/show',
  joinCampaign: 'campaigns/join',
  paymentChannels: 'dragonpay/channels',
  // https://best-dev.atlassian.net/wiki/spaces/MEG/pages/140738561/3.8.1.9.2+Cash+In
  cashIn: 'dragonpay/cashIn',
  requestEmailResetPassword: 'user/accounts/password/sendResetLink',
  resetPassword: 'user/accounts/password/reset',
  donateNow: 'events/:uid/donate',
  sendEmail: 'emails',
  sendEmailInquire: 'emails/inquire',

  // ***********************
  // ***** get method ******
  // ***********************
  allCampaigns: 'campaigns',
  joinedCampaigns: 'campaigns/entries',
  aboutUs: 'winners/testimonies',
  howItWorks: 'how-it-works',
  faqs: 'faqs',
  allAds: 'advertisements',
  getEvents: 'events',
  getEvent: 'events/:uid/show',
  // with parameters
  // [?page=1&size=10]
  item: 'campaigns/:uid',
  join: 'campaigns/join',
  adsOne: 'advertisements/:uid',
  // ***********************
  // ***** put method ******
  // ***********************
  changePassword: 'user/accounts/password/update',
  updateUserDetails: 'user/accounts/update',
};
