import { Injectable } from "@angular/core";
import { CanActivate, Router, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from "@angular/router";
import { Observable } from "rxjs";
import { LocalStorageKeys } from "../../util/local_storage_keys";
import { LocalStorageService } from "../local-storage/local-storage.service";

@Injectable({
  providedIn: 'root',
})
export class AuthenticantionGuard implements CanActivate {
  constructor(private storage: LocalStorageService, private router: Router) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    if (
      !this.storage.get(LocalStorageKeys.account) &&
      !this.storage.get(LocalStorageKeys.token)
    ) {
      this.router.navigateByUrl('/');
    }
    return true;
  }
}
