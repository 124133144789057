import { Component, OnInit } from '@angular/core';
import { ViewCampaignService } from 'src/app/features/campaign/service/view/view-campaign.service';

@Component({
  selector: 'app-donor-list',
  templateUrl: './donor-list.component.html',
  styleUrls: ['./donor-list.component.scss'],
})
export class DonorListComponent implements OnInit {
  constructor(public viewService: ViewCampaignService) {}

  ngOnInit(): void {}
}
