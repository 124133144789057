
  <div class="best-card-2 mt-5 ">
    <div class="table-responsive">
      <table class="table table-borderless text-center align-middle  white-text">
        <thead class="table-bottom-border f-med">
          <tr>
            <th scope="col">Event Name</th>
            <th scope="col">Number of Entry</th>
            <th scope="col">Event Status</th>
            <th scope="col">Check Winner</th>
          </tr>
        </thead>
        <tbody class="">
          <tr *ngFor="let event of eventsList" >
            <td (click)="openEventDetails(event)"  role="button">
              <u>{{ event.eventName }}</u>p</td>
            <td>{{ event.numberOfEntries }}</td>
            <td>{{ event.eventStatus }}</td>
            <td>
              <a 
                class="btn btn-sm purple-btn me-3 ms-4 mb-2" 
                href="#"
                role="button"
                data-bs-toggle="modal"
                data-bs-target="#winners"
                (click)="openWinnersModal(event)"
                >Check</a
              >
              <app-winners
                [id] = "eventId"
                [name] = "eventName"
                [slots] = "totalSlots"
                [amount] = "amountPerSlot"
                [prizes] = "prizes"
                [winners] = "winners"
              ></app-winners>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
 <div class="container mt-5" id="details" *ngIf="display">
  <app-event-details
    [id] = "eventId"
    [image] = "eventImage"
    [name] = "eventName"
    [startDate] = "startDate"
    [endDate] = "endDate"
    [raffleDrawDate] = "raffleDrawDate"
    [numberOfEntries] = "numberOfEntries"
    [totalEntriesAmount] = "totalEntriesAmount"
  ></app-event-details>
 </div>


