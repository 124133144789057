import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './features/home/home.component';
import { CampaignListComponent } from './features/campaign/campaign-list/campaign-list.component';
import { CampaignViewComponent } from './features/campaign/campaign-view/campaign-view.component';
import { AboutUsComponent } from './features/about-us/about-us.component';
import { FaqComponent } from './features/web-content/faq/faq.component';
import { HowItWorksComponent } from './features/web-content/how-it-works/how-it-works.component';
import { ContactUsComponent } from './features/contact-us/contact-us.component';
import { MyAccountComponent } from './features/profile/my-account/my-account.component';
import { TopUpWalletComponent } from './features/profile/top-up-wallet/top-up-wallet.component';
import { WelcomeComponent } from './features/welcome/welcome.component';
import { AuthenticantionGuard } from './core/services/auth/auth_guard';

const routes: Routes = [
  {
    path: HomeComponent.routeName,
    component: HomeComponent,
  },
  { path: '', redirectTo: HomeComponent.routeName, pathMatch: 'full' },
  {
    path: CampaignListComponent.routeName,
    component: CampaignListComponent,
  },
  {
    path: CampaignViewComponent.routeName,
    component: CampaignViewComponent,
  },
  {
    path: AboutUsComponent.routeName,
    component: AboutUsComponent,
  },
  {
    path: FaqComponent.routeName,
    component: FaqComponent,
  },
  {
    path: HowItWorksComponent.routeName,
    component: HowItWorksComponent,
  },
  {
    path: ContactUsComponent.routeName,
    component: ContactUsComponent,
  },
  {
    canActivate: [AuthenticantionGuard],
    path: MyAccountComponent.routeName,
    component: MyAccountComponent,
  },
  {
    canActivate: [AuthenticantionGuard],
    path: TopUpWalletComponent.routeName,
    component: TopUpWalletComponent,
  },
  {
    path: WelcomeComponent.routeName,
    component: WelcomeComponent,
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
