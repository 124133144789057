import { CampaignViewComponent } from 'src/app/features/campaign/campaign-view/campaign-view.component';
import { routeInterface } from './routei';
import { HomeComponent } from 'src/app/features/home/home.component';
import { CampaignListComponent } from 'src/app/features/campaign/campaign-list/campaign-list.component';
import { AboutUsComponent } from 'src/app/features/about-us/about-us.component';
import { ContactUsComponent } from 'src/app/features/contact-us/contact-us.component';
import { FaqComponent } from 'src/app/features/web-content/faq/faq.component';
import { HowItWorksComponent } from 'src/app/features/web-content/how-it-works/how-it-works.component';
import { MyAccountComponent } from 'src/app/features/profile/my-account/my-account.component';
import { TopUpWalletComponent } from 'src/app/features/profile/top-up-wallet/top-up-wallet.component';

export class myRouter {
  private static _instance = new myRouter();
  private constructor() {}
  static get instance() {
    return this._instance;
  }

  /// viewCampaign
  public static viewCampaign: routeInterface = {
    title: 'view campaign',
    name: 'viewCampaign',
    path: CampaignViewComponent.routeName,
  };

  public static campaignList: routeInterface = {
    title: 'Our Campaigns',
    name: 'ourCampaign',
    path: CampaignListComponent.routeName,
  };

  public static myAccount: routeInterface = {
    title: 'My Acount',
    name: 'myAccount',
    path: MyAccountComponent.routeName,
  };

  public static topUpWallet: routeInterface = {
    title: 'Top up wallet',
    name: 'topUpWallet',
    path: TopUpWalletComponent.routeName,
  };

  public static home: routeInterface = {
    title: 'Home',
    name: 'home',
    path: HomeComponent.routeName,
  };

  public static aboutUs: routeInterface = {
    title: 'About Us',
    name: 'aboutUs',
    path: AboutUsComponent.routeName,
  };

  public static contactUs: routeInterface = {
    title: 'Contact Us',
    name: 'contactUs',
    path: ContactUsComponent.routeName,
  };
  public static faq: routeInterface = {
    title: 'FAQ',
    name: 'faq',
    path: FaqComponent.routeName,
  };
  public static howItWorks: routeInterface = {
    title: 'How it works',
    name: 'howItWorks',
    path: HowItWorksComponent.routeName,
  };
}

export const myRoutesInstance = myRouter.instance;
export const routeList: routeInterface[] = [
  myRouter.viewCampaign,
  myRouter.home,
  myRouter.campaignList,
  myRouter.myAccount,
  myRouter.aboutUs,
  myRouter.contactUs,
  myRouter.howItWorks,
  myRouter.faq,
  myRouter.topUpWallet,
];
