import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'localizedNumber',
})
export class LocalizedNumberPipe implements PipeTransform {
  transform(value: number, decimal: number = 2): unknown {
    const numberString = value.toString();
    const [integerPart, decimalPart] = numberString.split('.');
    return `₱ ${this.addCommasToNumber(parseInt(integerPart))}.${
      decimalPart ?? '00'
    }`;
  }

  addCommasToNumber(number: number): string {
    const numberString = number.toString();
    const parts = numberString.split('.');
    const integerPart = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');

    // Reconstruct the number with commas added
    const result =
      parts.length > 1 ? integerPart + '.' + parts[1] : integerPart;

    return result;
  }
}
