<div class="modal-header border-0">
  <button
    type="button"
    class="close"
    aria-label="Close"
    (click)="modal.dismiss('Cross click')"
  >
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <div class="row mb-3 container">
    <div class="col-12">
      <p class="title"><u>TERMS AND CONDITIONS FOR HETO</u></p>
      <br />
      <ol>
        <li>
          <b>Acceptance of Terms</b>
          <p>
            By accessing and using HETO, you agree to comply with and be bound
            by these Terms and Conditions.
          </p>
        </li>
        <li>
          <b>Information on Membership</b>
          <p>
            To become a registered member of HETO, fill out the application form
            and submit via our website together with one any valid
            government-issued IDs that have a picture, name, and birthdate.
          </p>
        </li>
        <li>
          <b>Communication</b>
          <p>
            To enhance your experience with HETO, we may send you various forms
            of communication, including but not limited to administrative
            announcements, legally required notices, projects, promotions, and
            campaigns through the following medium including but not limited to
            Email, SMS, Phone Calls, Social Media and Other communication
            platform such as viber.
          </p>
        </li>
        <li>
          <b>Data Collection and Privacy Policy</b>
          <p>
            HETO collects your personal information in order to provide you with
            our products and services. We may also need to share your
            information with government agencies, essential suppliers,
            third-party service providers, related companies, and subsidiaries
            in order to administer your account, track usage, offer promotions
            and rewards, and provide other related services. We take the
            protection of your personal information seriously and comply with
            the Data Privacy Act of 2012.
          </p>
          <ul>
            <li>
              The information collected will be used to facilitate transactions,
              track and manage your account, and enhance our delivery of
              products and services.
            </li>
            <li>
              We will keep your information confidential and will not sell or
              share it with subsidiaries, thirdparty service providers,
              suppliers, related companies, or the public, except as necessary
              for business purposes or as required by law.
            </li>
            <li>
              We have implemented strict security measures and use systems to
              ensure that your personal data is kept confidential, secure, and
              accessible. Our employees are trained in handling personal data
              and we have internal controls in place to prevent and address any
              data breaches.
            </li>
            <li>Our company operates under the laws of the Philippines.</li>
            <li>
              Your information will be kept in our system as long as your
              account is active and or is required by any regulatory authority.
            </li>
            <li>
              If you have any complaints about the handling, correction, or
              deletion of your personal information, you can email
              <a href="mailto:support@heto.ph">support@heto.ph</a>
            </li>
            <br />
          </ul>
        </li>
        <li>
          <b>User Eligibility</b>
          <p>
            You must be at least 18 years old to use our platform. By using
            HETO, you represent and warrant that you are at least 18 years old
            and have the legal capacity to enter into these terms.
          </p>
        </li>
        <li>
          <b>Project Creation</b>
          <p>
            SWDA partners may propose crowd funding projects subject to approval
            and arrangement by HETO.
          </p>
          <p>
            Heto reserves the right to reject or remove any project at its
            discretion.
          </p>
        </li>
        <li>
          <b>Funding</b>
          <p>Users may contribute funds to projects on HETO.</p>
          <p>
            HETO is not responsible for the success or failure of a project.
          </p>
          <p>
            Contributors understand that their contributions are non-refundable.
          </p>
        </li>
        <li>
          <b>Fees</b>
          <p>HETO may charge fees for the use of its platform.</p>
          <p>
            Fee details are outlined in the fee schedule, which is incorporated
            by reference.
          </p>
        </li>
        <li>
          <b>Disbursement of Funds</b>
          <p>
            Fund raised will be disbursed to project creators after the
            completion of a succesful campaign.
          </p>
          <p>
            HETO reserves the right to withhold funds in case of suspected
            fraudulent activity.
          </p>
        </li>
        <li>
          <b>Intellectual Property</b>
          <p>
            Project creators retain ownership of their intellectual property.
          </p>
          <p>
            By posting content on HETO, users grant HETO a non-exclisive,
            royalty-free license to use, display, and distribute content.
          </p>
        </li>
        <li>
          <b>Prohibited Activities</b>
          <p>
            Users & Partners may not engage in illegal, fraudulent, or harmful
            activities on HETO.
          </p>
          <p>
            HETO reserves the right to suspend or terminate accounts engaging in
            prohibited activities.
          </p>
        </li>
        <li>
          <b>Limitation of Liability</b>
          <p>
            HETO is not liable for any damages, direct or indirect, arising from
            the use of its platform.
          </p>
          <p>HETO is not resposible for the actions or omissions of users.</p>
        </li>
        <li>
          <b>Termination</b>
          <p>
            HETO may terminate or suspend your account at any time for violation
            of these Terms.
          </p>
        </li>
        <li>
          <b>Changes to Terms</b>
          <p>
            HETO reserves the right to modify these Terms at any time. Users
            will be notified of changes, and continued use constitutes
            acceptance.
          </p>
        </li>
        <li>
          <b>Governing Law</b>
          <p>These Terms are governed by the laws of Philippines.</p>
          <p>
            By using HETO, you acknowledge that you have read, understood, and
            agree to these Terms and Conditions. If you do not agree, please do
            not use HETO.
          </p>
        </li>
        <li>
          <p>HETO</p>
          <p>
            General Inquiries/Support:<a href="mailto:support@heto.com.ph"
              >support@heto.com.ph</a
            >
          </p>
          <p>December 2023</p>
        </li>
      </ol>
    </div>
  </div>
</div>
<!-- dont remove it serves as spacer -->
<div class="modal-footer border-0"></div>
