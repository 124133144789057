<div class="donors-container">
  <table class="table table-bordered table-sm">
    <thead>
      <tr>
        <th scope="col">#</th>
        <th scope="col">NAMES</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let data of viewService.donorsList; let i = index">
        <th scope="row">{{ i + 1 }}</th>
        <td>
          <ng-container *ngIf="data.isAnonymous; else noPipe">
            {{ data.name | nameMask }}
          </ng-container>
          <ng-template #noPipe>
            {{ data.name }}
          </ng-template>
        </td>
      </tr>
    </tbody>
  </table>
</div>
