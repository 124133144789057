<div class="main-content">
  <div
    class="pick-number text-center mb-3"
    *ngFor="let in of mock(viewService.eventData.slotTotal); let i = index"
  >
    <div
      class="number-item center-item-content"
      [attr.slot-value]="i + 1"
      id="{{ i + 1 + '-number' }}"
      (click)="onClickNumber(i + 1)"
    >
      <div *ngIf="isTaken(i + 1)" class="taken-slot">X</div>
      {{ i + 1 }}
    </div>
  </div>
  <br />
  <!--input-->
  <div class="row col-12 ">
    <div class="col-8 mb-3">
      <input
        class="form-control input-design"
        type="number"
        step="2"
        [(ngModel)]="inputDonateAmount"
      />
    </div>
    <!-- /.col-8 input -->
    <div class="col-4">
      <div
        ngbDropdown
        class="d-inline-block"
        *ngIf="auth.isUserLogin; else elseNotLogin"
      >
        <button
          class="btn orange-btn f-weight-6"
          id="dropdownBasic1"
          ngbDropdownToggle
        >
          Submit
        </button>
        <div ngbDropdownMenu aria-labelledby="dropdownBasic1">
          <button ngbDropdownItem (click)="donateNow(true)">
            Submit as anonymous
          </button>
          <button ngbDropdownItem (click)="donateNow(false)">Submit now</button>
        </div>
      </div>
      <!-- /. dropdown btn -->
      <ng-template #elseNotLogin>
        <button
          class="btn orange-btn f-weight-6"
          id=""
          (click)="auth.openLoginModal()"
        >
          Submit
        </button>
      </ng-template>
    </div>
  </div>
  <!-- Donation amount buttons -->
  <div class="col-12 col-md-12 col-lg-12 mt-3">
    <div class="amount-buttons">
      <button class="btn" (click)="setDonationAmount(50)">50</button>
      <button class="btn" (click)="setDonationAmount(100)">100</button>
      <button class="btn" (click)="setDonationAmount(300)">300</button>
      <button class="btn" (click)="setDonationAmount(500)">500</button>
      <button class="btn" (click)="setDonationAmount(1000)">1000</button>
      <button class="btn" (click)="setDonationAmount(3000)">3000</button>
      <button class="btn" (click)="setDonationAmount(5000)">5000</button>
    </div>
  </div>
  <!-- /. row col-12 -->
</div>
