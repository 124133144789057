import { LocationStrategy } from '@angular/common';
import { Directive, Input } from '@angular/core';
import { ActivatedRoute, Router, RouterLinkWithHref } from '@angular/router';
import { routeList } from '../route-list';

@Directive({
  selector: 'a[appRouteName]',
})
export class RouteNameDirective extends RouterLinkWithHref {
  selectedRoute = new String();
  @Input('appRouteName')
  set appCustomRouteLink(val: string) {
    const selectedRoute = routeList.filter((element) => element.name == val);
    this.routerLink = '/' + selectedRoute[0].path;
    this.selectedRoute = '/' + selectedRoute[0].path;
  }
  @Input('customParams')
  /// to pass multiple use comma to separate
  set customParams(val: any) {
    const routeParams = JSON.parse(val.toString().replace(/'/g, '"'));
    const objectKeys = Object.keys(routeParams);
    if (objectKeys.length === 0) return;
    objectKeys.forEach((key) => {
      this.routerLink = this.selectedRoute
        .toString()
        .replace(`:${key}`, routeParams[key].toString());
    });
  }

  constructor(
    router: Router,
    route: ActivatedRoute,
    locationStrategy: LocationStrategy
  ) {
    super(router, route, locationStrategy);
  }
}
