import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { myRouter } from '../../route/route-list';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AuthService } from '../../services/auth/auth.service';
import { MainModalContainerService } from '../main-modal-container/service/main-modal-container.service';
import { modalType } from '../main-modal-container/modal-enum';

@Component({
  selector: 'app-header-nav',
  templateUrl: './header-nav.component.html',
  styleUrls: ['./header-nav.component.scss'],
})
export class HeaderNavComponent implements OnInit {
  routes = myRouter;
  @ViewChild('navbar', { static: true }) navbar!: ElementRef;
  constructor(
    private modalService: NgbModal,
    public auth: AuthService,
    private mainModalService: MainModalContainerService
  ) {}

  ngOnInit(): void {
    this.collapseNavbar();
  }

  openLoginModal(content: any) {
    this.modalService.open(content, { centered: true, size: 'lg' });
    this.mainModalService.setModalType(modalType.login);
  }
  openLogoutModal(content: any) {
    this.modalService.open(content, { centered: true, size: 'md' });
    this.mainModalService.setModalType(modalType.logout);
  }

  collapseNavbar() {
    console.log('test');
    // Access the nativeElement of the navbar and collapse it
    if (this.navbar.nativeElement.classList.contains('show')) {
      this.navbar.nativeElement.classList.remove('show');
    }
  }
}
