<div *ngIf="isLoading">
  <div class="d-flex justify-content-center">
    <div
      *ngFor="let in of mock(dotCount); let i = index"
      class="spinner-grow text-dark"
      role="status"
    ></div>
  </div>
</div>

<div *ngIf="!isLoading">
  <div *ngIf="dataElement">
    <ng-container *ngTemplateOutlet="dataElement"> </ng-container>
  </div>
</div>
