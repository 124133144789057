<div class="container">
  <div class="row mb-3">
    <div class="col-12">
      <p class="text-start f-lg theme-text-bold my-4 white-text">About Us</p>
      <div class="best-card best-card-shadow my-5">
        <p class="content text-center">
          Our objective is to provide an online support for NGO to raise
          awareness and to gather additional funds to support its mission. To
          provide safe, secure and interactive ways for Filipinos to support any
          NGO. To provide an alternative way for NGO to raise fund for its
          mission. Accelerate the presence of NGO to a wider audience.
        </p>
      </div>
      <hr />
      <div class="d-md-flex d-block justify-content-md-center gap-5">
        <div class="best-card best-card-shadow my-5 mx-2">
          <p class="text-center f-lg theme-text-bold white-text">VISION</p>
          <p class="content text-center">
            HETOs vision is to be the driving force behind the seamlessly
            connects NGOs with passionate donors, catalyzing a world where every
            act of giving has a purpose and every positive change is with reach.
          </p>
        </div>
        <div class="best-card best-card-shadow my-5 mx-2">
          <p class="text-center f-lg theme-text-bold white-text">MISSION</p>
          <p class="content text-center">
            Our mission is to forge meaningful connections between dedicated
            NGOs and compassionate donors, fostering positive change and
            amplifying the impact of their collective efforts.
          </p>
        </div>
      </div>
      <hr />
      <div class="best-card best-card-shadow my-5">
        <p class="content text-center">
          <span class="text-start f-lg theme-text-bold white-text"
            >Other Info</span
          >
          <br />
          We are one with the mission. Our platform shall serve as the converge
          of hope. Organizational maxim: Integrity, Customer-centric and
          Innovation. Government Oversight, Trusted NGO Partnership,
          Tech-powered and Secured Connection, Digital Footprint and Sustained
          Support.
        </p>
      </div>
    </div>
    <!-- /.col -->
  </div>
  <!-- /. row -->
  <!--hidden temporarily for prod-->
  <!-- <div class="row row-cols-3 mb-5">
    <div class="col-md-4 col-12 my-3 d-block justify-content-center" *ngFor="let testimonies of testimoniesList">
        <img 
          src="{{ 'https://' + testimonies.imageUrl }}"
          class="upload-circle mx-auto mb-3 best-card-shadow "
        />
      <p role="button" class="text-center text-style1">{{testimonies.testimony}}</p>
    </div>
  </div> -->
</div>
