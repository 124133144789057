<div class="modal-header border-0">
  <button
    type="button"
    class="close"
    aria-label="Close"
    (click)="modal.dismiss('Cross click')"
  >
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <ng-container>
    <iframe
      [src]="safeUrl()"
      height="500"
      width="100%"
      title="description"
    ></iframe>
  </ng-container>
</div>
<!-- dont remove it serves as spacer -->
<div class="modal-footer border-0">
  <a class="refresh-link" role="btn" (click)="refreshPage()">
    Payment Succesful? Click this to refresh wallet balance.
  </a>
</div>
