import { Component, OnInit } from '@angular/core';
import { Validators, AbstractControl, FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { myRouter } from 'src/app/core/route/route-list';
import { ApiHelperService } from 'src/app/core/services/api-helper/api-helper.service';
import { apiUrl } from 'src/app/core/services/api-helper/api_url';
import { UserDetailsInterface } from '../../../interface/user_details_interface';
import { UserDetailsResponseInterface } from '../../../interface/user_details_response_interface';
import { ResponseI } from 'src/app/core/interface/response_i';
import { StateI } from 'src/app/core/interface/state_i';

@Component({
  selector: 'app-view-user-details',
  templateUrl: './view-user-details.component.html',
  styleUrls: ['./view-user-details.component.scss'],
})
export class ViewUserDetailsComponent implements OnInit {
  // state hanlders
  updateUserDetailsState: StateI<ResponseI<UserDetailsResponseInterface>> = {
    isLoading: false,
    isFail: false,
    response: <ResponseI<UserDetailsResponseInterface>>{
      timestamp: '',
      status: 0,
      message: '',
      path: '',
      result: <UserDetailsResponseInterface>{},
    },
    isSuccess: false,
  };

  item: UserDetailsInterface = {
    uid: '',
    userAccountId: '',
    firstName: '',
    middleName: '',
    lastName: '',
    birthdate: '',
    email: '',
    mobileNumber: '',
    balance: 0,
  };

  fileInput: any;
  formData = new FormData();
  editMode: boolean = false;

  // form input builder
  updateUserDetailsForm = this.formBuilder.group({
    firstName: ['', Validators.required],
    lastName: ['', Validators.required],
    mobileNumber: [
      '',
      [
        Validators.required,
        Validators.pattern(/((^[0-9]{11}$)|(^[0-9]{12}$))/),
      ],
    ],
    email: ['', [Validators.required, Validators.email]],
  });

  get UserProfileControl() {
    return this.updateUserDetailsForm.controls;
  }

  userDetailsFormCtrl(name: string): AbstractControl {
    return this.updateUserDetailsForm.get(name);
  }

  constructor(
    private formBuilder: FormBuilder,
    public http: ApiHelperService<UserDetailsInterface>,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.getUserDetails();
  }

  getUserDetails() {
    this.http.getDetails({
      url: apiUrl.userDetails,
      successCallBack: this.profileCallback,
    });
  }

  profileCallback = (): void => {
    let itemResponse = this.http.apiDataState.response.result;
    this.item = itemResponse;
    this.setUserDetails();
  };

  setUserDetails(): void {
    this.updateUserDetailsForm.markAllAsTouched();
    this.UserProfileControl.firstName.setValue(this.item.firstName);
    this.UserProfileControl.lastName.setValue(this.item.lastName);
    this.UserProfileControl.mobileNumber.setValue(this.item.mobileNumber);
    this.UserProfileControl.email.setValue(this.item.email);
  }

  updateUserDetails() {
    this.http.put({
      url: apiUrl.updateUserDetails,
      data: this.updateUserDetailsForm.value,
      successCallBack: this.updateCallback,
    });
  }

  updateCallback = (): void => {
    let response = this.updateUserDetailsState;
    console.log(response);
    setTimeout(() => {
      window.location.reload();
    }, 1000);
  };

  cancel() {
    setTimeout(() => {
      this.router.navigate([`${myRouter.home.path}`]).then(() => {
        window.location.reload();
      });
    }, 1000);
  }

  onChangeFileValidId(event) {
    const formData = this.formData;
    formData.append('imageId', null);
    console.log(event.target.files.length);

    if (event.target.files.length <= 1) {
      formData.append('imageId', event.target.files[0]);
      console.log(event.target.files[0]);
      return;
    }
  }
  onChangeFileSelfieId(event) {
    const formData = this.formData;
    formData.append('imageSelfie', null);
    console.log(event.target.files.length);

    if (event.target.files.length <= 1) {
      formData.append('imageSelfie', event.target.files[0]);
      console.log(event.target.files[0]);

      return;
    }
  }

  toggleEditMode() {
    this.editMode = !this.editMode;
  }
}
