import { Component, Input, OnInit } from '@angular/core';
import { MainModalContainerService } from './service/main-modal-container.service';
import { modalType } from './modal-enum';
@Component({
  selector: 'app-main-modal-container',
  templateUrl: './main-modal-container.component.html',
  styleUrls: ['./main-modal-container.component.scss'],
})
export class MainModalContainerComponent implements OnInit {
  @Input() modal;
  constructor(public modalService: MainModalContainerService) {
    this.modalService.modal = this.modal;
  }

  ngOnInit(): void {}

 
}
