<div class="row">
  <button class="btn btn-circle" (click)="goToDonate()">Donate now</button>
  <!-- <div class="mb-4"><br /></div> -->
  <!-- <app-coins class="mb-5"></app-coins> -->
  <div class="col-md-6 col-12" *ngIf="!viewService.isLoading">
    <p class="text-center f-lg theme-text-bold text-uppercase">
      {{ viewService.eventData.title }}
    </p>
    <p class="text-center f-lg theme-text-bold text-capitalize">
      {{ viewService.eventData.name }}
    </p>
    <ngb-carousel *ngIf="viewService.eventData.eventContents.length >= 1">
      <ng-template
        ngbSlide
        *ngFor="
          let content of viewService.eventData.eventContents;
          let i = index
        "
      >
        <div class="picsum-img-wrapper campaing-card">
          <img
            src="{{ viewService.getImage(content) }}"
            alt="Random first slide"
          />
        </div>
      </ng-template>
    </ngb-carousel>
    <!-- /. campaign images -->
    <div class="text-center my-4">
      <p>
        <span class="chosenSlot theme-text fw-semibold">{{
          viewService.takenSlots.length
        }}</span
        ><span class="f-med theme-text mb-2 fw-semibold">Out of 100 goal</span>
      </p>
      <p class="f-med theme-text mb-2 fw-semibold">
        {{ viewService.accumulatedAmount.toString() | localizedNumber }}
        raised of
        {{ viewService.eventData.goalDonation | localizedNumber }} goal
      </p>
    </div>
  </div>
  <!-- /. left side upper  -->
  <div class="col-md-6 col-12">
    <app-tabs></app-tabs>
  </div>
  <!-- /. right side lower  -->
</div>
<!-- /. row -->
