<div class="your-slider" *ngIf="data.length >= 1">
  <ng-container *ngFor="let item of data; let i = index">
    <a
      class="done-a"
      *ngIf="auth.isUserLogin; else elseNotLogin"
      appRouteName="viewCampaign"
      customParams="{'id':'{{ item.eventId }}'}"
    >
      <div
        class="done-img"
        [style.backgroundImage]="'url(' + getImage(item.eventContents) + ')'"
      >
        <div class="img-container"></div>
      </div>
    </a>
    <ng-template #elseNotLogin>
      <a class="done-a" role="button" (click)="auth.openLoginModal()">
        <div
          class="done-img"
          [style.backgroundImage]="'url(' + getImage(item.eventContents) + ')'"
        >
          <div class="img-container"></div></div
      ></a>
    </ng-template>
  </ng-container>
</div>