<div class="row">
    <div class="col-md-6 col-12  turquoise-text">
      <div class="container details text-center f-med" >
        <p>{{ name }}</p>
        <img
          src="{{  image !== '[]'
            ? 'https://' +
              image
            : '/assets/dummy.jpeg'
            }}"
          class=" d-block w-100 slider-image">
      </div>
    </div>
    <div class="col-md-6 col-12  white-text">
      <div class="container details pt-50">
        <div class="mb-5"><br></div>
        <p> Start Date: {{ startDate }}</p>
        <p> End Date: {{ endDate }}</p>
        <p> Raffle Draw Date: {{ raffleDrawDate }}</p>
        <p> Number of Entry: {{ numberOfEntries }}</p>
        <p> Total Entry Amount: {{ totalEntriesAmount }}</p>
      </div>
    </div>
  </div>