import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { CashInModalComponent } from '../cash-in-modal/cash-in-modal.component';
import { ApiHelperService } from 'src/app/core/services/api-helper/api-helper.service';
import { SweetAlert2Service } from 'src/app/core/services/sweet_alert2/sweet_alert2';
import { CashInInterface } from '../../interface/cash_in_interface';
import { apiUrl } from 'src/app/core/services/api-helper/api_url';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { SanitizedUrlService } from '../../service/sanitized-url.service';

@Component({
  selector: 'app-top-up-form',
  templateUrl: './top-up-form.component.html',
  styleUrls: ['./top-up-form.component.scss'],
})
export class TopUpFormComponent implements OnInit {
  inputAmount: number = 0;
  inputCode: String = '';
  @Input() selectedID: string;
  @Input() pmcTypeSelected: number;
  @Output() clearSelectedPmc: EventEmitter<any> = new EventEmitter();
  @ViewChild(CashInModalComponent) cashInModalComponent: CashInModalComponent;
  cashInUrl: string = '';
  constructor(
    public cashIn: ApiHelperService<CashInInterface>,
    private swal: SweetAlert2Service,
    private modalService: NgbModal,
    private urlService: SanitizedUrlService
  ) {}

  ngOnInit(): void {}

  submitCashIn(): void {
    const selectedID = this.selectedID;
    console.log(selectedID, 'teterer');
    if (selectedID == '') {
      this.swal.toastInfo('Please select a payment channel');
      return;
    }
    if (this.inputAmount <= 0) {
      this.swal.toastInfo('Please enter amount ');
      return;
    }
    this.cashIn.post({
      url: apiUrl.cashIn,
      data: {
        ProcId: selectedID,
        Amount: this.inputAmount,
      },
      successCallBack: this.submitCashInCallback,
    });
  }

  openLoginModal(content: any) {
    this.modalService.open(content, { centered: true, size: 'lg' });
  }

  submitCashInCallback = (): void => {
    this.inputAmount = 0;
    this.clearSelectedPmc.emit();
    let res = this.cashIn.apiDataState.response.result;
    let modalEl = document.getElementById('cashInBtn');
    modalEl.click();
    this.cashInUrl = res.url;
    this.urlService.sanitzedUrl = res.url;
    console.log(res.url);
    return;
  };
}
