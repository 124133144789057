import { Component, ElementRef, OnDestroy, OnInit } from '@angular/core';
import { ApiHelperService } from 'src/app/core/services/api-helper/api-helper.service';
import { apiUrl } from 'src/app/core/services/api-helper/api_url';
import {
  EvenResponseI,
  NgoEventInterface,
  eventContentInterface,
} from '../campaign-list/interface/events_interface';
import { AuthService } from 'src/app/core/services/auth/auth.service';
import { ViewCampaignService } from '../service/view/view-campaign.service';
declare var $: any;

@Component({
  selector: 'app-done-campaign',
  templateUrl: './done-campaign.component.html',
  styleUrls: ['./done-campaign.component.scss'],
})
export class DoneCampaignComponent implements OnInit, OnDestroy {
  data: NgoEventInterface[] = [];
  constructor(
    private el: ElementRef,
    public http: ApiHelperService<EvenResponseI>,
    public auth: AuthService,
    public viewService: ViewCampaignService
  ) {}
  ngOnDestroy(): void {
    this.viewService.clear();
  }

  ngOnInit(): void {
    this.getDoneEvents();
  }
  ngAfterViewInit() {}

  initSlider(): void {
    $(this.el.nativeElement.querySelector('.your-slider')).slick({
      autoplay: true,
      autoplaySpeed: 2000,
      infinite: false,
      slidesToShow: 3,
      dots: true,
      slidesToScroll: 1,
      responsive: [
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 1,
          },
        },
      ],
    });
  }

  getDoneEvents(): void {
    this.http.post({
      url: apiUrl.getEvents,
      data: {
        startDate: this.dateminus(10),
        endDate: this.dateminus(0),
        donorsPercentage: 100,
        isCompleted: true,
      },
      isAuth: false,
      successCallBack: this.successCallBack,
    });
  }

  getImage(imageList: eventContentInterface[]): string {
    if (imageList.length >= 1) {
      const url = 'https://' + imageList[0].url;
      console.log('Image URL:', url); // Log URL to console for debugging
      return url;
    }
    return 'assets/noimg.jpg'; // Default image if none available
  }
  
  
  successCallBack = (): void => {
    const data = this.http.apiDataState.response.result.data;
    this.data = data;
    console.log('Data:', this.data);
    setTimeout(() => {
      this.initSlider();
    }, 100); // Increase timeout if needed
  };
  
  dateminus(minus: number): String {
    var currentDate = new Date();
    currentDate.setDate(currentDate.getDate() - minus);
    var year = currentDate.getFullYear();
    var month = (currentDate.getMonth() + 1).toString().padStart(2, '0'); // Months are zero-based
    var day = currentDate.getDate().toString().padStart(2, '0');
    return year + '-' + month + '-' + day;
  }
  getBackgroundImageUrl(): string {
    // Replace this with your logic to get the image URL dynamically
    return 'assets/6.jpg';
  }
}
