import { Component, OnInit } from '@angular/core';
import { ApiHelperService } from 'src/app/core/services/api-helper/api-helper.service';
import { apiUrl } from 'src/app/core/services/api-helper/api_url';
import { topUpEnum } from '../my-account/my-account.component';
import { PaymentChannelInterface } from './interface/payment_channel_interface';
import { AuthService } from 'src/app/core/services/auth/auth.service';

@Component({
  selector: 'app-top-up-wallet',
  templateUrl: './top-up-wallet.component.html',
  styleUrls: ['./top-up-wallet.component.scss'],
})
export class TopUpWalletComponent implements OnInit {
  static readonly routeName: string = 'top-up';

  pmcData: PaymentChannelInterface[] = [];
  filteredPmcData: PaymentChannelInterface[] = [];
  inputAmount: number = 0;
  selectedPmc: PaymentChannelInterface = {
    procId: '',
    shortName: '',
    longName: '',
    logo: '',
    currencies: '',
    url: '',
    realTime: '',
    pwd: '',
    defaultBillerId: '',
    hasTxnPwd: false,
    hasManualEnrollment: false,
    type: 0,
    status: '',
    remarks: '',
    dayOfWeek: '',
    startTime: '',
    endTime: '',
    minAmount: 0,
    maxAmount: 0,
    mustRedirect: false,
    surcharge: 0,
    hasAltRefNo: false,
    cost: 0,
    hasSettlement: false,
    merchant: '',
  };

  topUpType = topUpEnum;
  pmcTypeSelected: number;

  constructor(public apiPmc: ApiHelperService<[PaymentChannelInterface]>, public auth: AuthService) {}

  ngOnInit(): void {
    this.getPaymentChannels();
    this.auth.getMyProfile();
  }

  getPaymentChannels(): void {
    this.apiPmc.post({
      url: apiUrl.paymentChannels,
      successCallBack: this.getPmcCallback,
    });
  }

  getPmcCallback = (): void => {
    let res = this.apiPmc.apiDataState;
    this.pmcData = res.response.result;
    this.filterPmcData(128);
  };

  filterPmcData(pmcType: number): void {
    this.clearSelectedPmc();
    this.pmcTypeSelected = pmcType;
    this.filteredPmcData = this.pmcData.filter((data) => data.type == pmcType);
  }

  selectPmc(data: PaymentChannelInterface) {
    this.selectedPmc = data;
    window.scroll({
      top: 0,
      left: 0,
      behavior: 'smooth', // Optional: Add smooth scrolling behavior
    });
  }

  clearSelectedPmc(): void {
    this.selectedPmc = {
      procId: '',
      shortName: '',
      longName: '',
      logo: '',
      currencies: '',
      url: '',
      realTime: '',
      pwd: '',
      defaultBillerId: '',
      hasTxnPwd: false,
      hasManualEnrollment: false,
      type: 0,
      status: '',
      remarks: '',
      dayOfWeek: '',
      startTime: '',
      endTime: '',
      minAmount: 0,
      maxAmount: 0,
      mustRedirect: false,
      surcharge: 0,
      hasAltRefNo: false,
      cost: 0,
      hasSettlement: false,
      merchant: '',
    };
  }
}
