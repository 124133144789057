<div class="best-card-2 mt-5">
  <form
    class="row"
    [formGroup]="changePasswordForm"
    (ngSubmit)="submitChangePasswordForm()"
  >
    <div class="row mb-3 pd-custom">
      <p class="mb-0 f-lg theme-text-bold turquoise-text w-100">Change Password</p>
      <p class="mb-4 f-lg theme-text white-text f-med w-100">
        Your password shoudle be atleast 10 characters
      </p>
      <div class="col-md-4">
        <div class="mb-3">
          <label for="currentPassword" class="form-label"
            >Current Password</label
          >
          <input
            type="password"
            class="form-control input-design"
            [ngClass]="{
              'is-invalid':
                changePasswordFormCtrl('oldPassword').errors &&
                changePasswordFormCtrl('oldPassword').touched
            }"
            id="currentPassword"
            formControlName="oldPassword"
            autocomplete="off"
          />
          <div
            class="invalid-feedback"
            *ngIf="changePasswordFormCtrl('oldPassword').hasError('required')"
          >
            This field is required!
          </div>
        </div>
      </div>
      <!-- /.firstName -->
      <div class="col-md-4">
        <div class="mb-3">
          <label for="password" class="form-label">Password</label>
          <input
            type="password"
            class="form-control input-design"
            [ngClass]="{
              'is-invalid':
                changePasswordFormCtrl('newPassword').errors &&
                changePasswordFormCtrl('newPassword').touched
            }"
            id="newPassword"
            formControlName="newPassword"
            autocomplete="off"
          />
          <div
            class="invalid-feedback"
            *ngIf="changePasswordFormCtrl('newPassword')"
          >
            <p
              *ngIf="changePasswordFormCtrl('newPassword').hasError('required')"
            >
              This field is required!
            </p>
            <p
              *ngIf="changePasswordFormCtrl('newPassword').hasError('pattern')"
            >
              Atleast 10 characters consist of numbers, uppercase and lowercase
              letters!
            </p>
          </div>
        </div>
      </div>
      <!-- /.lastName-->
      <div class="col-md-4">
        <div class="mb-3">
          <label for="confirmPassword" class="form-label"
            >Confirm Password</label
          >
          <input
            type="password"
            class="form-control input-design"
            [ngClass]="{
              'is-invalid':
                changePasswordFormCtrl('confirmPassword').errors &&
                changePasswordFormCtrl('confirmPassword').touched
            }"
            id="confirmPassword"
            formControlName="confirmPassword"
            autocomplete="off"
          />
          <div
            class="invalid-feedback"
            *ngIf="changePasswordFormCtrl('confirmPassword')"
          >
            <p
              *ngIf="
                changePasswordFormCtrl('confirmPassword').hasError('required')
              "
            >
              This field is required!
            </p>
            <p
              *ngIf="
                changePasswordFormCtrl('confirmPassword').hasError(
                  'passwordMismatch'
                )
              "
            >
              Password dont match!
            </p>
          </div>
        </div>
      </div>
    </div>
    <div class="row justify-content-center mt-3 w-100">
      <div class="col-md-4">
        <div class="d-flex justify-content-center">
          <button
            class="btn orange-btn f-weight-6 my-btn-sm mx-3"
            type="submit"
          >
            Save
          </button>
        </div>
      </div>
    </div>
  </form>
</div>
