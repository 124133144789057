import { Component, OnInit } from '@angular/core';
import { AuthService } from '../../services/auth/auth.service';
import { ApiHelperService } from '../../services/api-helper/api-helper.service';

@Component({
  selector: 'app-wallet',
  templateUrl: './wallet.component.html',
  styleUrls: ['./wallet.component.scss'],
})
export class WalletComponent implements OnInit {
  userBalance: number = 0;
  constructor(public auth: AuthService) {}

  ngOnInit(): void {
    this.updateWallet();
    this.auth.getDataObservable().subscribe((data) => {
      this.userBalance = data.balance;
    });
  }

  updateWallet(): void {
    this.auth.getMyProfile();
  }
}
