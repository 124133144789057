import { Component, OnInit } from '@angular/core';
import { Validators, FormBuilder, AbstractControl } from '@angular/forms';
import { ApiHelperService } from '../../services/api-helper/api-helper.service';
import { apiUrl } from '../../services/api-helper/api_url';
import { EmailResponseInterface } from './interface/email_response_interface';
import { StateI } from '../../interface/state_i';
import { ResponseI } from '../../interface/response_i';
import { MainModalContainerService } from '../main-modal-container/service/main-modal-container.service';

@Component({
  selector: 'app-send-email-forgot-password',
  templateUrl: './send-email-forgot-password.component.html',
  styleUrls: ['./send-email-forgot-password.component.scss'],
})
export class SendEmailForgotPasswordComponent implements OnInit {
  success: any;

  // state hanlders
  emailState: StateI<ResponseI<EmailResponseInterface>> = {
    isLoading: false,
    isFail: false,
    response: <ResponseI<EmailResponseInterface>>{
      timestamp: '',
      status: 0,
      message: '',
      path: '',
      result: <EmailResponseInterface>{},
    },
    isSuccess: false,
  };

  // form input builder
  emailForm = this.formBuilder.group({
    email: ['', [Validators.required, Validators.email]],
  });

  constructor(
    private formBuilder: FormBuilder,
    private http: ApiHelperService<EmailResponseInterface>,
    public mainModalService: MainModalContainerService
  ) {
    /// init the state
    this.emailState = this.http.apiDataState;
  }

  ngOnInit(): void {}

  setLoading(isLoading: boolean) {
    this.emailState.isLoading = isLoading;
  }

  get emailControl() {
    return this.emailForm.controls;
  }

  emailFormCtrl(name: string): AbstractControl {
    return this.emailForm.get(name);
  }

  submitEmailForm() {
    this.http.post({
      url: apiUrl.requestEmailResetPassword,
      data: this.emailForm.value,
      isAuth: false,
      successCallBack: this.callback,
    });
  }

  callback = (): void => {
    let response = this.emailState;
    this.success = this.emailState;
  };
}
