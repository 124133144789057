<div id="card-items" *ngIf="data.length >= 1" class="container-fluid">
  <div class="row">
    <div class="col-12">
      <div class="label text-center mb-4 mt-4">ACTIVE CAMPAIGNS</div>
    </div>
  </div>
  <div
    class="row g-5 row-cols-1 row-cols-sm-2 row-cols-md-2 text-start align-items-cente"
  >
    <div
      class="col position-relative mt-4"
      *ngFor="let item of data; let i = index"
    >
      <h4 class="mb-0 turquoise-text text-uppercase elips-1">
        {{ item.name }}
      </h4>
      <h6 class="mb-1 turquoise-text elips-1">{{ item.title }}</h6>
      <div class="item-cards">
        <div class="item">
          <img src="{{ getImage(item.eventContents) }}" class="image" />
        </div>
        <div class="position-absolute bottom-0">
          <a
            *ngIf="auth.isUserLogin; else elseNotLogin"
            class="btn btn-sm purple-btn me-3 ms-4 rounded-0"
            appRouteName="viewCampaign"
            customParams="{'id':'{{ item.eventId }}'}"
            >{{ item.title }}</a
          >
          <ng-template #elseNotLogin>
            <a
              role="button"
              class="btn btn-sm purple-btn me-3 ms-4 rounded-0"
              (click)="auth.openLoginModal()"
              >{{ item.title }}</a
            >
          </ng-template>
        </div>
        <!-- /. position absolute title -->
      </div>
      <!-- /.item cards -->
    </div>
    <!-- /.col -->
  </div>
  <!-- /.row -->
  <div class="w-100 my-2">
    <app-campaign-paginate
      [paginateData]="paginateData"
      (updatePageNumber)="updatePageNumber($event)"
    ></app-campaign-paginate>
  </div>
  <div class="label text-center mb-4 mt-4">PREVIOUS Campaign</div>
  <app-done-campaign></app-done-campaign>
</div>
