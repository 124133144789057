import { ApiErrorI } from "../../interface/api_error_i";

export class ApiErrorResponse implements ApiErrorI {
  timestamp: string;
  status: number;
  error: string;
  message: string;
  path: string;
  constructor(init: {
    timestamp: string;
    status: number;
    error: string;
    message: string;
    path: string;
  }) {
    this.timestamp = init.timestamp;
    this.status = init.status;
    this.message = init.message;
    this.path = init.path;
    this.error = init.error;
  }
}
