import { Component, Input, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, Validators } from '@angular/forms';
import { ApiHelperService } from '../../services/api-helper/api-helper.service';
import { LocalStorageService } from '../../services/local-storage/local-storage.service';
import { LoginResponseInterface } from '../../interface/authenticantion/login_response_interface';
import { apiUrl } from '../../services/api-helper/api_url';
import { LocalStorageKeys } from '../../util/local_storage_keys';
import { myRouter } from '../../route/route-list';
import { Router } from '@angular/router';
import { Location } from '@angular/common';
import { MainModalContainerService } from '../main-modal-container/service/main-modal-container.service';

@Component({
  selector: 'app-login-form',
  templateUrl: './login-form.component.html',
  styleUrls: ['./login-form.component.scss'],
  providers: [ApiHelperService],
})
export class LoginFormComponent implements OnInit {
  @Input() modal;
  isLoading: boolean = false;

  loginForm = this.formBuilder.group({
    username: ['', Validators.required],
    password: ['', Validators.required],
  });

  constructor(
    private formBuilder: FormBuilder,
    private http: ApiHelperService<LoginResponseInterface>,
    private storage: LocalStorageService,
    private router: Router,
    public mainModalService: MainModalContainerService,
    private location: Location
  ) {
    this.isLoading = this.http.apiDataState.isLoading;
  }

  ngOnInit(): void {}
  get loginControl() {
    return this.loginForm.controls;
  }

  loginFormCtrl(name: string): AbstractControl {
    return this.loginForm.get(name);
  }

  openCreateAccount() {
    this.modal.dismiss('Cross click');
  }
  submitLoginForm(): void {
    this.http.post({
      url: apiUrl.login,
      data: this.loginForm.value,
      isAuth: false,
      successCallBack: this.loginCallBack,
    });
  }
  loginCallBack = (): void => {
    let apiSubscribe = this.http.apiDataState;
    const userAccount = apiSubscribe.response.result.userAccount;
    const token = apiSubscribe.response.result.loginToken;
    this.storage.set(LocalStorageKeys.account, JSON.stringify(userAccount));
    this.storage.set(LocalStorageKeys.token, token);
    setTimeout(() => {
      const currentUrl = this.location.path().split('/');
      if (currentUrl.includes('campaign')) {
        window.location.reload();
      } else {
        this.router.navigate([`${myRouter.home.path}`]).then(() => {
          window.location.reload();
        });
      }
    }, 600);
  };
}
