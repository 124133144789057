<form [formGroup]="loginForm" (ngSubmit)="submitLoginForm()">
  <div class="col-md-10 col-lg-9 col-12 mx-auto">
    <div class="mb-3">
      <p class="label text-center turquoise-text">Login</p>
    </div>
    <div class="subLabel text-center mb-4">
      Lets help together
      <a
        class="link"
        role="button"
        (click)="
          mainModalService.setModalType(mainModalService.modalTypes.register)
        "
        >Create an Account
      </a>
    </div>
    <div class="mb-3">
      <label for="username" class="form-label">Phone Number</label>
      <input
        class="form-control input-design"
        [ngClass]="{
          'is-invalid':
            loginFormCtrl('username').errors &&
            loginFormCtrl('username').touched
        }"
        id="username"
        formControlName="username"
        aria-describedby="usernameHelp"
      />
      <div
        class="invalid-feedback"
        *ngIf="loginFormCtrl('username').hasError('required')"
      >
        This field is required!
      </div>
    </div>
    <div class="mb-4">
      <label for="password" class="form-label">Password</label>
      <input
        type="password"
        formControlName="password"
        class="form-control input-design"
        [ngClass]="{
          'is-invalid':
            loginFormCtrl('password').errors &&
            loginFormCtrl('password').touched
        }"
        id="password"
        autocomplete="off"
      />
      <div
        class="invalid-feedback"
        *ngIf="loginFormCtrl('password').hasError('required')"
      >
        This field is required!
      </div>
    </div>
  </div>
  <!-- /.col -->
  <div class="col-12">
    <div class="text-center my-3">
      <a
        class="forgot-password"
        role="button"
        (click)="
          mainModalService.setModalType(mainModalService.modalTypes.sendEmailForgot)
        "
        >Forgot Your Password?
      </a>
    </div>
    <div class="d-flex">
      <button
        class="btn orange-btn f-weight-6 align-self-center mx-auto btn-med"
        type="submit"
        [disabled]="!loginForm.valid"
      >
        <div>LOG IN</div>
      </button>
    </div>
  </div>
</form>
