<section id="top-up">
  <div class="container">
    <div class="row">
      <app-top-up-form
        [selectedID]="selectedPmc.procId"
        [pmcTypeSelected]="pmcTypeSelected"
        (clearSelectedPmc)="clearSelectedPmc()"
        class="col-12"
      ></app-top-up-form>
      <!-- /. col -->
      <div class="col-12">
        <div class="d-md-flex d-block ">
          <div class="group-radio-btn">
            <input
              type="radio"
              class="btn-check"
              name="options-outlined"
              id="ewallet"
              autocomplete="off"
              hidden
              (click)="filterPmcData(128)"
            />
            <label
              class="btn btn-outline-secondary raidio-label w-100"
              for="ewallet"
              >E-Wallet &nbsp;&nbsp;&nbsp;</label
            >
          </div>
          <!-- /.group -->
          <div class="group-radio-btn">
            <input
              type="radio"
              class="btn-check"
              name="options-outlined"
              id="overTheCounter"
              autocomplete="off"
              hidden
              (click)="filterPmcData(2)"
            />
            <label
              class="btn btn-outline-secondary raidio-label w-100"
              for="overTheCounter"
              >Over-The-Counter &nbsp;&nbsp;&nbsp;</label
            >
          </div>
          <!-- /.group -->
          <div class="group-radio-btn">
            <input
              type="radio"
              class="btn-check"
              name="options-outlined"
              id="onlineBanking"
              autocomplete="off"
              hidden
              (click)="filterPmcData(1)"
            />
            <label
              class="btn btn-outline-secondary raidio-label w-100"
              for="onlineBanking"
              >Online Banking &nbsp;&nbsp;&nbsp;</label
            >
          </div>
          <!-- /.group -->
          <!-- <div class="group-radio-btn">
            <input
              type="radio"
              class="btn-check"
              name="options-outlined"
              id="existing"
              autocomplete="off"
              hidden
              (click)="filterPmcData(0)"
            />
            <label
              class="btn btn-outline-secondary raidio-label w-100"
              for="existing"
              >Redeem Coupon Code &nbsp;&nbsp;&nbsp;</label
            >
          </div> -->
          <!-- /.group -->
        </div>
        <div class="hr-1"></div>
        <ul
          class="list-group list-group-flush pmc "
          *ngIf="filteredPmcData.length >= 1"
        >
          <li
            class="list-group-item"
            role="button"
            (click)="selectPmc(data)"
            [ngClass]="{ 'active-li': selectedPmc.procId == data.procId }"
            *ngFor="let data of filteredPmcData"
          >
            <div class="row">
              <div
                class="col-3 d-flex align-items-center justify-content-center"
              >
                <img class="pmc-logo ms-auto" [src]="data.logo" alt="" />
              </div>
              <div class="col-7">
                <h4 class="theme-text-700 f-md mb-0 longname">{{ data.longName }}</h4>
                <p class="mb-0 remarks" [innerHTML]="data.remarks"></p>
              </div>
              <div
                class="col-1 d-flex align-items-center justify-content-center"
              >
                <i
                  class="f-lg fa fa-check-circle me-auto purple-text"
                  *ngIf="selectedPmc.procId == data.procId"
                ></i>
              </div>
            </div>
          </li>
          <!-- /.row -->
        </ul>
        <!-- /. pmc -->
      </div>
    </div>
    <!-- /.row -->
  </div>
  <!-- /.container -->
</section>
