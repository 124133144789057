<div class="modal-header border-0">
  <button
    type="button"
    class="close"
    aria-label="Close"
    (click)="modal.dismiss('Cross click')"
  >
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <div *ngIf="modalService.selectedModal === modalService.modalTypes.login">
    <app-login-form></app-login-form>
  </div>
  <div *ngIf="modalService.selectedModal === modalService.modalTypes.forgot">
    <app-forgot-password></app-forgot-password>
  </div>
   <div *ngIf="modalService.selectedModal === modalService.modalTypes.sendEmailForgot">
    <app-send-email-forgot-password></app-send-email-forgot-password>
  </div>
  <div *ngIf="modalService.selectedModal === modalService.modalTypes.register">
    <app-register-form></app-register-form>
  </div>
  <div *ngIf="modalService.selectedModal === modalService.modalTypes.logout">
    <app-logout-form></app-logout-form>
  </div>
</div>
<!-- dont remove it serves as spacer -->
<div class="modal-footer border-0"></div>
