import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'nameMask'
})
export class NameMaskPipe implements PipeTransform {
  transform(value: string): string {
    if (!value) {
      return '';
    }

    const firstLetter = value.charAt(0);
    const secondLetter = value.charAt(1);
    const lastLetter = value.charAt(value.length - 1);
    const maskedLetters = value.slice(2, -1).replace(/\S/g, '*');

    return firstLetter +secondLetter+ maskedLetters + lastLetter;
  }
}
