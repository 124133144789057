<div class="col-lg-6 col-md-8 col-12 mx-auto">
  <ng-template [ngIf]="pmcTypeSelected == 0" [ngIfElse]="enterAmount">
    <label for="enterAmount" class="form-label">Enter Coupon Code</label>

    <input
      class="form-control input-design"
      id="inputCode"
      [(ngModel)]="inputCode"
      placeholder="Enter your code here (BESTPICKCODE)"
    />
  </ng-template>

  <ng-template #enterAmount>
    <label for="enterAmount" class="form-label">Enter Amount</label>

    <input
      class="form-control input-design"
      id="enterAmount"
      type="number"
      step="0.01"
      [(ngModel)]="inputAmount"
    />
  </ng-template>

  <div class="text-center my-2">
    <a role="button" class="white-text theme-text-700 font-lg"
      >Choose Your Payment Option</a
    >
  </div>
  <div class="text-center">
    <ng-template [ngIf]="pmcTypeSelected == 0" [ngIfElse]="enterAmountBtn">
      <button
        class="btn orange-btn f-weight-6 align-self-center mx-auto btn-med"
        type="button"
        (click)="redeemCode()"
      >
        <ng-container *ngIf="cashIn.apiDataState.isLoading">
          <div class="spinner-grow" role="status">
            <span class="sr-only">Loading...</span>
          </div>
          <div class="spinner-grow" role="status">
            <span class="sr-only">Loading...</span>
          </div>
          <div class="spinner-grow" role="status">
            <span class="sr-only">Loading...</span>
          </div>
        </ng-container>
        <ng-container *ngIf="!cashIn.apiDataState.isLoading">
          CONFIRM
        </ng-container>
      </button>
    </ng-template>

    <ng-template #enterAmountBtn>
      <button
        id="confirm-button"
        class="btn orange-btn f-weight-6 align-self-center mx-auto btn-med"
        type="button"
        (click)="submitCashIn()"
      >
        <ng-container *ngIf="cashIn.apiDataState.isLoading">
          <div class="spinner-grow" role="status">
            <span class="sr-only">Loading...</span>
          </div>
          <div class="spinner-grow" role="status">
            <span class="sr-only">Loading...</span>
          </div>
          <div class="spinner-grow" role="status">
            <span class="sr-only">Loading...</span>
          </div>
        </ng-container>
        <ng-container *ngIf="!cashIn.apiDataState.isLoading">
          CONFIRM
        </ng-container>
      </button>
    </ng-template>
  </div>
</div>
<button
  type="button"
  class="btn btn-primary"
  data-bs-toggle="modal"
  id="cashInBtn"
  data-bs-target="#cashIn"
  hidden
  (click)="openLoginModal(content)"
>
  test
</button>
<ng-template #content let-modal>
  <app-cash-in-modal
    [modal]="modal"
    [cashInUrl]="cashInUrl"
  ></app-cash-in-modal>
</ng-template>
