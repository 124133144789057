import { Component, OnInit } from '@angular/core';
import { ApiHelperService } from 'src/app/core/services/api-helper/api-helper.service';
import { apiUrl } from 'src/app/core/services/api-helper/api_url';
import { JoinedEventsInterface } from './interface/joined_events_interface';

@Component({
  selector: 'app-my-joined-events',
  templateUrl: './my-joined-events.component.html',
  styleUrls: ['./my-joined-events.component.scss'],
})
export class MyJoinedEventsComponent implements OnInit {
  eventsList: JoinedEventsInterface[] = [];
  uid: string = '';
  event: any;
  eventId: number = 0;
  eventName: string = '';
  totalSlots: number = 0;
  amountPerSlot: number = 0;
  prizes: string = '';
  winners: string = '';
  consolationPrize: string[] = [];
  grandPrize: string = '';
  eventImage: string = '';
  startDate: string = '';
  endDate: string = '';
  raffleDrawDate: string = '';
  numberOfEntries: number = 0;
  totalEntriesAmount: number = 0;
  display = false;

  constructor(private http: ApiHelperService<JoinedEventsInterface[]>) {}

  ngOnInit(): void {
    this.getJoinedEvents();
  }

  getJoinedEvents() {
    this.http.get({
      url: apiUrl.joinedCampaigns,
      successCallBack: this.successGetJoinedEvents,
    });
  }

  successGetJoinedEvents = (): void => {
    let res = this.http.apiDataState;
    this.eventsList.push(...res.response.result);
  };

  openWinnersModal(event: any): void {
    this.event = event;
    this.eventId = this.event.uid;
    this.eventName = this.event.eventName;
    this.totalSlots = this.event.totalSlots;
    this.amountPerSlot = this.event.amountPerSlot;
    this.winners = this.event.winners;
    console.log('cd', this.event);
    this.prizes = this.event.prizes.split(':');
  }

  openEventDetails(event: any): void {
    this.display = !this.display;
    this.event = event;
    this.eventId = this.event.uid;
    this.eventName = this.event.eventName;
    this.startDate = this.event.startDate;
    this.endDate = this.event.endDate;
    this.raffleDrawDate = this.event.raffleDrawDate;
    this.numberOfEntries = this.event.numberOfEntries;
    this.totalEntriesAmount = this.event.totalEntriesAmount;
    this.eventImage = this.event.uploadedContents
      .split(',')[0]
      .replace('[', '')
      .replace(']', '');
  }
}
