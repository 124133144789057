import { Component, Input, OnInit, TemplateRef } from '@angular/core';
import { MockDataService } from '../../services/mock/mock-data.service';

@Component({
  selector: 'app-dot-loading-handler',
  templateUrl: './dot-loading-handler.component.html',
  styleUrls: ['./dot-loading-handler.component.scss'],
})
export class DotLoadingHandlerComponent implements OnInit {
  @Input() dataElement?: TemplateRef<any>;
  @Input() dotCount: number = 1;
  @Input() isLoading: boolean = false;
  mock: Function;

  constructor(private mockData: MockDataService) {
    this.mock = this.mockData.createMockData;
  }

  ngOnInit(): void {}
}
