import { Injectable, isDevMode } from '@angular/core';
import {
  HttpClient,
  HttpErrorResponse,
  HttpHeaders,
} from '@angular/common/http';
import { ApiErrorResponse } from './api_error_helper';
import { catchError } from 'rxjs/operators';
import { throwError } from 'rxjs';
import { environment } from 'src/environments/environment';
import { StateI } from '../../interface/state_i';
import { ResponseI } from '../../interface/response_i';
import { LocalStorageService } from '../local-storage/local-storage.service';
import { ApiParameterI } from '../../interface/api_params_i';
import { LocalStorageKeys } from '../../util/local_storage_keys';
import { responseStatus } from '../../util/response_status';
import { SweetAlert2Service } from '../sweet_alert2/sweet_alert2';
import { UserAccountInterface } from '../../interface/authenticantion/user_account_interface';
@Injectable({
  providedIn: 'root',
})
export class ApiHelperService<T> {
  baseUrl = environment.apiURL;
  // state hanlder
  dialog = document.querySelector('#loader');
  apiDataState: StateI<ResponseI<T>> = {
    isLoading: false,
    isFail: false,
    isSuccess: false,
    response: <ResponseI<T>>{
      timestamp: '',
      status: 0,
      message: '',
      path: '',
      result: <T>{},
    },
  };

  constructor(
    private http: HttpClient,
    private storage: LocalStorageService,
    private swal: SweetAlert2Service
  ) {}

  setLoading(isLoading: boolean) {
    this.apiDataState.isLoading = isLoading;
    const modalLoading = this.dialog as HTMLDialogElement;
    if (isLoading) {
      this.logToConsole('loading');
      modalLoading.showModal();
    } else {
      this.logToConsole('close');
      modalLoading.close();
    }
  }
  setSuccess(isSuccess: boolean) {
    this.apiDataState.isSuccess = isSuccess;
  }

  put({
    url,
    data,
    headers,
    isAuth = true,
    successCallBack,
    failCallBack,
    doneCallBack,
  }: ApiParameterI): StateI<ResponseI<T>> {
    const token = this.storage.get(LocalStorageKeys.token);
    const headerOption = {
      headers: new HttpHeaders({
        Authorization: `Bearer ${token}`,
      }),
    };
    this.setSuccess(false);
    this.setLoading(false);

    const httpResponse = this.http
      .put<ResponseI<T>>(
        `${this.baseUrl}${url}`,
        data,
        isAuth ? headerOption : { headers }
      )
      .pipe(catchError(this.handleErrors));
    this.setLoading(true);
    httpResponse.subscribe(
      (response) => {
        console.log(response);
        let message = response.message;
        this.apiDataState.response = response;
        let status = response.status;
        this.logToConsole(response);
        this.setLoading(false);
        if (status == responseStatus.failed) {
          this.logToConsole(message);
          this.setSuccess(false);
          return;
        }
        this.logToConsole(message);
        this.setSuccess(true);
        if (successCallBack != null) {
          successCallBack();
        }
      },
      (error) => {
        this.setLoading(false);
        this.setSuccess(false);
        this.logToConsole(error);
        this.swal.toastError(error);
        if (failCallBack != null) {
          failCallBack();
        }
      },
      () => {
        this.setLoading(false);
        if (doneCallBack != null) {
          doneCallBack();
        }
      }
    );

    return this.apiDataState;
  }

  post({
    url,
    data,
    headers,
    isAuth = true,
    successCallBack,
    failCallBack,
    doneCallBack,
  }: ApiParameterI): StateI<ResponseI<T>> {
    const token = this.storage.get(LocalStorageKeys.token);
    const headerOption = {
      headers: new HttpHeaders({
        Authorization: `Bearer ${token}`,
      }),
    };
    this.setSuccess(false);
    this.setLoading(false);

    const httpResponse = this.http
      .post<ResponseI<T>>(
        `${this.baseUrl}${url}`,
        data,
        isAuth ? headerOption : { headers }
      )
      .pipe(catchError(this.handleErrors));
    this.setLoading(true);
    httpResponse.subscribe(
      (response) => {
        console.log(response);
        let message = response.message;
        this.apiDataState.response = response;
        let status = response.status;
        this.logToConsole(response);
        this.setLoading(false);
        if (status == responseStatus.failed) {
          this.logToConsole(message);
          this.setSuccess(false);
          return;
        }
        this.logToConsole(message);
        this.setSuccess(true);
        if (successCallBack != null) {
          successCallBack();
        }
      },
      (error) => {
        this.setLoading(false);
        this.setSuccess(false);
        this.logToConsole(error);
        this.swal.toastError(error);
        if (failCallBack != null) {
          failCallBack();
        }
      },
      () => {
        this.setLoading(false);
        if (doneCallBack != null) {
          doneCallBack();
        }
      }
    );

    return this.apiDataState;
  }

  get({
    url,
    data,
    headers,
    isAuth = true,
    successCallBack,
    failCallBack,
    doneCallBack,
  }: ApiParameterI): StateI<ResponseI<T>> {
    const token = this.storage.get(LocalStorageKeys.token);
    const headerOption = {
      headers: new HttpHeaders({
        Authorization: `Bearer ${token}`,
      }),
    };
    this.setSuccess(false);
    this.setLoading(true);

    const httpResponse = this.http
      .get<ResponseI<T>>(
        `${this.baseUrl}${url}`,
        isAuth ? headerOption : { headers }
      )
      .pipe(catchError(this.handleErrors));

    httpResponse.subscribe(
      (response) => {
        let message = response.message;
        this.apiDataState.response = response;
        let status = response.status;
        this.setLoading(false);
        this.logToConsole(response);
        if (status == responseStatus.failed) {
          this.logToConsole(message);
          this.setLoading(false);
          this.setSuccess(false);
          if (failCallBack != null) {
            setTimeout(() => {
              typeof failCallBack === 'function' && failCallBack();
            }, 500);
          }
          return;
        } // status failed
        this.logToConsole(message);
        this.setSuccess(true);
        if (successCallBack != null) {
          setTimeout(() => {
            typeof successCallBack === 'function' && successCallBack();
          }, 500);
        }
      },
      (error) => {
        this.logToConsole(error || 'something went wrong');
        this.setLoading(false);
        this.setSuccess(false);
      },
      () => {
        this.setLoading(false);
        if (doneCallBack != null) {
          doneCallBack();
        }
      }
    );

    return this.apiDataState;
  }

  logToConsole(message: any) {
    if (isDevMode()) {
      console.log(message);
    }
  }
  /// api error exception
  revokeToken(): void {
    this.logToConsole('revoke token');
  }
  handleErrors(error: any) {
    if (error instanceof HttpErrorResponse) {
      let apiError = new ApiErrorResponse(error.error);
      if (apiError) {
        // this.logToConsole(apiError.message);
        if (apiError.message == 'JWT Token has expired') {
          // this.logToConsole(['JWT Token has expired']);
          this.revokeToken();
        }
        return throwError(apiError.message || 'Api Error');
      }
      // this.logToConsole('Server Client Error');
      return throwError(error.message || 'Server Client Error');
    }
    // this.logToConsole('Something went wrong');
    return throwError(error.message || 'Something went wrong');
  }

  ////// remove this
  getDetails({
    url,
    data,
    headers,
    isAuth = true,
    successCallBack,
  }: ApiParameterI): StateI<ResponseI<T>> {
    const userAccount: UserAccountInterface = JSON.parse(
      this.storage.get(LocalStorageKeys.account)
    );
    const token = this.storage.get(LocalStorageKeys.token);
    const headerOption = {
      headers: new HttpHeaders({
        Authorization: `Bearer ${token}`,
      }),
    };
    this.setSuccess(false);

    const httpResponse = this.http.post<ResponseI<T>>(
      `${this.baseUrl}${url}`,
      {
        mobileNumber: userAccount.username,
      },
      headerOption
    );
    this.setLoading(true);
    httpResponse.subscribe(
      (response) => {
        console.log(response);
        let message = response.message;
        this.apiDataState.response = response;
        let status = response.status;
        this.setLoading(false);
        if (status == responseStatus.failed) {
          this.swal.toastWarning(message);
          this.setSuccess(false);
          alert(message);
          return;
        }
        this.swal.toastSuccess(message);
        this.setSuccess(true);
        if (successCallBack != null) {
          successCallBack();
        }
      },
      (error) => {
        this.swal.toastError(error['error']['message'] + '!');
        this.setLoading(false);
        this.setSuccess(false);
        console.log(error);
      }
    );
    return this.apiDataState;
  }
}
