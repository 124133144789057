import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import {
  NgbModule,
} from '@ng-bootstrap/ng-bootstrap';
import { HeaderNavComponent } from './core/components/header-nav/header-nav.component';
import { FooterNavComponent } from './core/components/footer-nav/footer-nav.component';
import { HomeComponent } from './features/home/home.component';
import { CampaignListComponent } from './features/campaign/campaign-list/campaign-list.component';
import { CampaignViewComponent } from './features/campaign/campaign-view/campaign-view.component';
import { RouteNameDirective } from './core/route/routeName/route-name.directive';
import { AboutUsComponent } from './features/about-us/about-us.component';
import { FaqComponent } from './features/web-content/faq/faq.component';
import { HowItWorksComponent } from './features/web-content/how-it-works/how-it-works.component';
import { ContactUsComponent } from './features/contact-us/contact-us.component';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AdsComponent } from './features/ads/ads.component';
import { LoginFormComponent } from './core/components/login-form/login-form.component';
import { LoadingComponent } from './core/components/loading/loading.component';
import { DoneCampaignComponent } from './features/campaign/done-campaign/done-campaign.component';
import { LocalizedNumberPipe } from './core/pipes/localized-number/localized-number.pipe';
import { TabsComponent } from './features/campaign/campaign-view/components/tabs/tabs.component';
import { DonorListComponent } from './features/campaign/campaign-view/components/tabs/donor-list/donor-list.component';
import { DonateNowComponent } from './features/campaign/campaign-view/components/tabs/donate-now/donate-now.component';
import { NameMaskPipe } from './core/pipes/name-mask/name-mask.pipe';
import { ShareComponent } from './features/campaign/campaign-view/components/tabs/share/share.component';
import { TopUpWalletComponent } from './features/profile/top-up-wallet/top-up-wallet.component';
import { MyAccountComponent } from './features/profile/my-account/my-account.component';
import { WalletComponent } from './core/components/wallet/wallet.component';
import { TermsAndConditionsComponent } from './core/components/terms-and-conditions/terms-and-conditions.component';
import { PrivacyPolicyComponent } from './core/components/privacy-policy/privacy-policy.component';
import { TopUpFormComponent } from './features/profile/top-up-wallet/components/top-up-form/top-up-form.component';
import { CashInModalComponent } from './features/profile/top-up-wallet/components/cash-in-modal/cash-in-modal.component';
import { WelcomeComponent } from './features/welcome/welcome.component';
import { ViewUserDetailsComponent } from './features/profile/my-account/component/view-user-details/view-user-details.component';
import { ChangePasswordComponent } from './features/profile/my-account/component/change-password/change-password.component';
import { MyJoinedEventsComponent } from './features/profile/my-account/component/my-joined-events/my-joined-events.component';
import { RegisterFormComponent } from './core/components/register-form/register-form.component';
import { KycFormComponent } from './core/components/kyc-form/kyc-form.component';
import { MainModalContainerComponent } from './core/components/main-modal-container/main-modal-container.component';
import { ForgotPasswordComponent } from './core/components/forgot-password/forgot-password.component';
import { DotLoadingHandlerComponent } from './core/components/dot-loading-handler/dot-loading-handler.component';
import { SendEmailForgotPasswordComponent } from './core/components/send-email-forgot-password/send-email-forgot-password.component';
import { WinnersComponent } from './features/profile/my-account/component/my-joined-events/winners/winners.component';
import { EventDetailsComponent } from './features/profile/my-account/component/my-joined-events/event-details/event-details.component';
import { CampaignPaginateComponent } from './features/campaign/campaign-paginate/campaign-paginate.component';
import { LogoutFormComponent } from './core/components/logout-form/logout-form.component';
@NgModule({
  declarations: [
    AppComponent,
    HeaderNavComponent,
    FooterNavComponent,
    HomeComponent,
    CampaignListComponent,
    CampaignViewComponent,
    RouteNameDirective,
    AboutUsComponent,
    FaqComponent,
    HowItWorksComponent,
    ContactUsComponent,
    AdsComponent,
    LoginFormComponent,
    LoadingComponent,
    DoneCampaignComponent,
    LocalizedNumberPipe,
    TabsComponent,
    DonorListComponent,
    DonateNowComponent,
    NameMaskPipe,
    ShareComponent,
    TopUpWalletComponent,
    MyAccountComponent,
    WalletComponent,
    TermsAndConditionsComponent,
    PrivacyPolicyComponent,
    TopUpFormComponent,
    CashInModalComponent,
    WelcomeComponent,
    ViewUserDetailsComponent,
    ChangePasswordComponent,
    MyJoinedEventsComponent,
    RegisterFormComponent,
    KycFormComponent,
    MainModalContainerComponent,
    ForgotPasswordComponent,
    DotLoadingHandlerComponent,
    SendEmailForgotPasswordComponent,
    WinnersComponent,
    EventDetailsComponent,
    CampaignPaginateComponent,
    LogoutFormComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    NgbModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
  ],
  providers: [],
  bootstrap: [AppComponent],
})
export class AppModule {}
