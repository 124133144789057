import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-event-details',
  templateUrl: './event-details.component.html',
  styleUrls: ['./event-details.component.scss'],
})
export class EventDetailsComponent implements OnInit {
  @Input() id: number = 0;
  @Input() image: string = '';
  @Input() name: string = '';
  @Input() startDate: string = '';
  @Input() endDate: string = '';
  @Input() raffleDrawDate: string = '';
  @Input() numberOfEntries: number = 0;
  @Input() totalEntriesAmount: number = 0;
  constructor() {}

  ngOnInit(): void {}
}
