<nav aria-label="Page navigation example my-2" *ngIf="paginateData != null">
  <ul class="pagination justify-content-end">
    <li
      class="page-item"
      *ngIf="paginateData.currentPage != 1; else disablePrevPage"
    >
      <a
        class="page-link"
        role="button"
        (click)="navigatePagination(paginateData.currentPage - 1)"
        >Previous</a
      >
    </li>
    <ng-template #disablePrevPage>
      <li class="page-item disabled">
        <a class="page-link" href="#" tabindex="-1" aria-disabled="true"
          >Previous</a
        >
      </li>
    </ng-template>
    <!-- /. if previous page and has previous page -->

    <li
      class="page-item"
      *ngFor="let in of mock(paginateData.totalPages); let i = index"
    >
      <a
        class="page-link"
        [ngClass]="{ 'active-paginate': paginateData.currentPage == i + 1 }"
        role="button"
        (click)="navigatePagination(i + 1)"
        >{{ i + 1 }}</a
      >
    </li>
    <!-- /. generate pagination -->

    <li
      class="page-item"
      *ngIf="
        paginateData.currentPage != paginateData.totalPages;
        else disableNextPage
      "
    >
      <a
        class="page-link"
        role="button"
        (click)="navigatePagination(paginateData.currentPage + 1)"
        >Next</a
      >
    </li>
    <ng-template #disableNextPage>
      <li class="page-item disabled">
        <a class="page-link" href="#" tabindex="-1" aria-disabled="true"
          >Next</a
        >
      </li>
    </ng-template>
    <!-- /. if next page and has next page -->
  </ul>
</nav>
