import { Injectable } from '@angular/core';
import { modalType } from '../modal-enum';

@Injectable({
  providedIn: 'root',
})
export class MainModalContainerService {
  modal;

  selectedModal: modalType = modalType.login;
  constructor() {}

  setModalType(selectedType: modalType): void {
    this.selectedModal = selectedType;
  }

  get modalTypes(): typeof modalType {
    return modalType;
  }
}
