<ng-container *ngIf="!isMobile">
  <ngb-carousel *ngIf="adsListWeb.length >= 1">
    <ng-template ngbSlide *ngFor="let ads of adsListWeb; let i = index">
      <div class="picsum-img-wrapper ads">
        <img src="{{ 'https://' + ads }}" alt="Random first slide" />
      </div>
      <div class="carousel-caption">
        <a
          class="donateBtn orange-btn badge rounded-pill me-5 mb-4"
          role="btn"
          aria-current="page"
          appRouteName="ourCampaign"
          >Donate Now
        </a>
      </div>
    </ng-template>
  </ngb-carousel></ng-container
>

<ng-container *ngIf="isMobile">
  <ngb-carousel *ngIf="adsListMobile.length >= 1">
    <ng-template ngbSlide *ngFor="let ads of adsListMobile; let i = index">
      <div class="picsum-img-wrapper ads">
        <img src="{{ 'https://' + ads }}" alt="Random first slide" />
      </div>
      <div class="carousel-caption">
        <a
          class="donateBtn orange-btn badge rounded-pill me-5 mb-4"
          role="btn"
          aria-current="page"
          appRouteName="ourCampaign"
          >Donate Now
        </a>
      </div>
    </ng-template>
  </ngb-carousel></ng-container
>
