<form [formGroup]="emailForm" (ngSubmit)="submitEmailForm()">
  <div class="col-md-10 col-lg-10 col-12 mx-auto">
    <div class="mb-4 reset">
      <label class="resetLabel"> Reset your password</label><br />
      <span>A password reset link will be sent to your email.</span>
    </div>
    <div class="mb-2">
      <label for="email" class="form-label">Email</label>
      <input
        type="text"
        formControlName="email"
        class="form-control input-design"
        [ngClass]="{
          'is-invalid':
            emailFormCtrl('email').errors && emailFormCtrl('email').touched
        }"
        id="email"
      />
      <div class="invalid-feedback" *ngIf="emailFormCtrl('email')">
        <p *ngIf="emailFormCtrl('email').hasError('required')">
          This field is required!
        </p>
        <p *ngIf="emailFormCtrl('email').hasError('email')">Invalid email !</p>
      </div>
    </div>
    <div class="mb-4 text-end">
      <label class="success" *ngIf="success">Succesfully sent!</label>
    </div>
  </div>
  <!-- /.col -->
  <div class="col-12">
    <app-dot-loading-handler
      [dotCount]="3"
      [dataElement]="myBtn"
      [isLoading]="emailState.isLoading"
    ></app-dot-loading-handler>
    <ng-template #myBtn let-data="data">
      <div class="d-flex">
        <button
          *ngIf="!success"
          class="btn orange-btn f-weight-6 align-self-center mx-auto btn-med"
          type="submit"
          [disabled]="!emailForm.valid"
        >
          SEND
        </button>
        <a
          *ngIf="success"
          class="btn orange-btn f-weight-6 align-self-center mx-auto btn-med"
          role="button"
          (click)="
            mainModalService.setModalType(mainModalService.modalTypes.forgot)
          "
        >
          NEXT
        </a>
      </div>
    </ng-template>
  </div>
</form>
<!-- /.form -->
