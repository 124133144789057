<div class="d-flex flex-row-reverse container align">
  <span class="coins">Wallet: {{ userBalance | localizedNumber }}
    <a class="plus-button" role="btn" appRouteName="topUpWallet">
      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="custom-plus-icon" viewBox="0 0 16 16">
        <path fill-rule="evenodd" d="M8 2a.5.5 0 0 1 .5.5v5h5a.5.5 0 0 1 0 1h-5v5a.5.5 0 0 1-1 0v-5h-5a.5.5 0 0 1 0-1h5v-5A.5.5 0 0 1 8 2"/>
      </svg>
    </a>
  </span>
</div>

