import { Injectable } from '@angular/core';
import { ApiHelperService } from 'src/app/core/services/api-helper/api-helper.service';
import {
  NgoEventInterface,
  donorI,
  eventContentInterface,
  getEventI,
} from '../../campaign-list/interface/events_interface';
import { apiUrl } from 'src/app/core/services/api-helper/api_url';
import { Meta } from '@angular/platform-browser';
import { environment } from 'src/environments/environment';
import { Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ViewCampaignService {
  uid: string;

  closedSlotNumber = 0;

  slotsTaken: [] = [];
  accumulatedAmount: number = 0;
  takenSlots: number[] | [] = [];

  slotsTakenSubject = new Subject<number[] | []>();

  eventData: NgoEventInterface = {
    uid: 0,
    name: '',
    eventId: '',
    dateCreated: '',
    title: '',
    details: '',
    minAmount: 0,
    maxAmount: 0,
    donorsPercentage: 0,
    status: '',
    reviewedBy: undefined,
    dateReviewed: undefined,
    approvedBy: undefined,
    dateApproved: undefined,
    startDate: undefined,
    endDate: undefined,
    updatedBy: undefined,
    dateUpdated: undefined,
    deletedBy: undefined,
    dateDeleted: undefined,
    eventContents: [],
    slotTotal: 0,
    totalDonation: 0,
    goalDonation: 0,
  };

  isLoading: boolean = false;

  donorsList: donorI[] | [] = [];

  constructor(
    private http: ApiHelperService<getEventI>,
    private metaService: Meta
  ) {
    this.isLoading = this.http.apiDataState.isLoading;
  }

  getEventDetails() {
    this.http.get({
      isAuth: false,
      url: `${apiUrl.getEvent.replace(':uid', this.uid)}`,
      successCallBack: this.successCallBack,
    });
  }

  successCallBack = (): void => {
    // TODO
    // subject for refactor
    let itemResponse = this.http.apiDataState.response.result;
    this.eventData = itemResponse.event;
    this.donorsList = itemResponse.donors;
    this.setSlotTaken(itemResponse.chosenSlots);
    this.accumulatedAmount = itemResponse.accumulatedAmount;
    const slots = this.http.apiDataState.response.result.chosenSlots;
    this.takenSlots = slots;
    this.computeRemaingSlot(itemResponse.event);
    this.setupMetaTags();
  };

  getSlotTakenObservable(): Observable<number[] | []> {
    return this.slotsTakenSubject.asObservable();
  }

  setSlotTaken(newData: []): void {
    this.slotsTaken = newData;
    this.slotsTakenSubject.next(this.slotsTaken);
  }

  computeRemaingSlot(data: NgoEventInterface): void {
    let minPerSlot = data.minAmount;
    let slotTotal = data.slotTotal;
    const goalDonation = minPerSlot * slotTotal;
    this.eventData.goalDonation = goalDonation;
  }

  getImage(imageData: eventContentInterface): string {
    // Check if content has an image URL
    if (imageData && imageData.url) {
      return 'https://' + imageData.url;
    } else {
      // If no image URL is available, return the path to your default image in the assets folder
      return 'assets/noimg.jpg';
    }
  }

  removeSelectedNumbers(): void {
    var divsToRemove = document.querySelectorAll('div.number-item');
    divsToRemove.forEach(function (el) {
      var childDivs = el.querySelectorAll('div');
      if (childDivs != null) {
        el.classList.remove('selected-number');
      }
    });
  }

  clear(): void {
    this.closedSlotNumber = 0;
    this.eventData = {
      uid: 0,
      name: '',
      eventId: '',
      dateCreated: '',
      title: '',
      details: '',
      minAmount: 0,
      maxAmount: 0,
      donorsPercentage: 0,
      status: '',
      reviewedBy: undefined,
      dateReviewed: undefined,
      approvedBy: undefined,
      dateApproved: undefined,
      startDate: undefined,
      endDate: undefined,
      updatedBy: undefined,
      dateUpdated: undefined,
      deletedBy: undefined,
      dateDeleted: undefined,
      eventContents: [],
      slotTotal: 0,
      totalDonation: 0,
      goalDonation: 0,
    };
    this.slotsTaken = [];
    this.accumulatedAmount = 0;
    this.takenSlots = [];
    this.donorsList = [];
  }

  isNumberInSlotsTaken(slotNumber: number): boolean {
    let arrayHolder = [];
    arrayHolder = this.slotsTaken;
    return arrayHolder.includes(slotNumber);
  }

  // subject for refactor
  setupMetaTags(): void {
    // seo start
    this.metaService.updateTag({
      name: 'title',
      content: this.eventData.title,
    });

    this.metaService.updateTag({
      name: 'description',
      content: this.eventData.name,
    });

    this.metaService.updateTag({
      name: 'image',
      content: this.getImage(this.eventData.eventContents[0]),
    });
    // seo end

    // fb start
    const baseUrl = environment.baseUrl;

    this.metaService.updateTag({
      property: 'og:title',
      content: this.eventData.title,
    });
    this.metaService.updateTag({
      property: 'og:description',
      content: this.eventData.name,
    });
    this.metaService.updateTag({
      property: 'og:image',
      content: this.getImage(this.eventData.eventContents[0]),
    });
    this.metaService.updateTag({
      property: 'og:url',
      content: `${baseUrl}campaign/${this.uid}`,
    });
    // fb end

    // twitter start
    this.metaService.updateTag({
      name: 'twitter:title',
      content: this.eventData.title,
    });
    this.metaService.updateTag({
      name: 'twitter:description',
      content: this.eventData.name,
    });
    this.metaService.updateTag({
      name: 'twitter:image',
      content: this.getImage(this.eventData.eventContents[0]),
    });
  }
}
