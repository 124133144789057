import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, Validators } from '@angular/forms';
import { ApiHelperService } from '../../services/api-helper/api-helper.service';
import { CustomValidationService } from '../../services/custom_validation/custom-validation.service';
import { ResetResponseInterface } from './interface/reset_response_interface';
import { StateI } from '../../interface/state_i';
import { ResponseI } from '../../interface/response_i';
import { apiUrl } from '../../services/api-helper/api_url';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss'],
})
export class ForgotPasswordComponent implements OnInit {
  success: any;

  // state hanlders
  resetState2: StateI<ResponseI<ResetResponseInterface>> = {
    isLoading: false,
    isFail: false,
    response: <ResponseI<ResetResponseInterface>>{
      timestamp: '',
      status: 0,
      message: '',
      path: '',
      result: <ResetResponseInterface>{},
    },
    isSuccess: false,
  };

  // form input builder
  resetForm2 = this.formBuilder.group(
    {
      code: ['', Validators.required],
      newPassword: [
        '',
        [
          Validators.required,
          Validators.pattern(
            /^(?=\D*\d)(?=[^a-z]*[a-z])(?=[^A-Z]*[A-Z]).{8,30}$/
          ),
        ],
      ],
      confirmPassword: ['', Validators.required],
    },
    {
      validator: this.customValidator.passwordMatchValidator(
        'newPassword',
        'confirmPassword'
      ),
    }
  );
  constructor(
    private formBuilder: FormBuilder,
    private http: ApiHelperService<ResetResponseInterface>,
    private customValidator: CustomValidationService
  ) {}

  ngOnInit(): void {}

  setLoading(isLoading: boolean) {
    this.resetState2.isLoading = isLoading;
  }

  get resetrControl() {
    return this.resetForm2.controls;
  }

  resetFormCtrl2(name: string): AbstractControl {
    return this.resetForm2.get(name);
  }

  submitResetForm2() {
    this.http.post({
      url: apiUrl.resetPassword,
      data: this.resetForm2.value,
      isAuth: false,
      successCallBack: this.callback,
    });
  }

  callback = (): void => {
    let response = this.resetState2;
    this.success = this.resetState2;
  };
}
