import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-share',
  templateUrl: './share.component.html',
  styleUrls: ['./share.component.scss'],
})
export class ShareComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}

  shareFacebook(): void {
    window.open(
      `https://www.facebook.com/sharer/sharer.php?u=${window.location.href}`,
      '_black',
      'location=yes,height=570,width=520,scrollbars=yes,status=yes'
    );
  }

  shareTwitter(): void {
    const twitterContent = `@TWitterACCOUNT \n ${window.location.href}`; // @TwitterACCOUNT ng Heto
    window.open(
      `https://twitter.com/intent/tweet?text=${twitterContent}&url=${window.location.href}`
    );
  }
}
