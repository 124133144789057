<ul ngbNav #nav="ngbNav" [(activeId)]="active" class="nav-tabs">
  <li [ngbNavItem]="1">
    <a ngbNavLink>DETAILS</a>
    <ng-template ngbNavContent>
      <div class="details">
        <div [innerHTML]="viewService.eventData.details"></div>
      </div>
    </ng-template>
  </li>
  <li [ngbNavItem]="2">
    <a ngbNavLink c>LIST OF DONORS</a>
    <ng-template ngbNavContent>
      <app-donor-list></app-donor-list>
    </ng-template>
  </li>
  <li [ngbNavItem]="3"  id="campaign-donate-now-tab">
    <a ngbNavLink>DONATE NOW</a>
    <ng-template ngbNavContent>
      <app-donate-now></app-donate-now>
    </ng-template>
  </li>
  <li [ngbNavItem]="4">
    <a ngbNavLink>SHARE</a>
    <ng-template ngbNavContent>
      <app-share></app-share>
    </ng-template>
  </li>
</ul>

<div [ngbNavOutlet]="nav" class="mt-2"></div>
