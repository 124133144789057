import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MockDataService } from 'src/app/core/services/mock/mock-data.service';
import { CampaignsPaginateInterface } from '../campaign-list/interface/campaign_paginate_interace';

@Component({
  selector: 'app-campaign-paginate',
  templateUrl: './campaign-paginate.component.html',
  styleUrls: ['./campaign-paginate.component.scss'],
})
export class CampaignPaginateComponent implements OnInit {
  @Input() paginateData: CampaignsPaginateInterface;
  @Output() updatePageNumber: EventEmitter<number> = new EventEmitter();

  mock: Function;

  constructor(private mockService: MockDataService) {
    this.mock = this.mockService.createMockData;
  }

  ngOnInit(): void {}

  navigatePagination(pageNumber: number): void {
    this.updatePageNumber.emit(pageNumber);
  }
}
