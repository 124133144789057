<form [formGroup]="registerForm" (ngSubmit)="submitRegisterForm()">
    <div class="mb-3">
        <p class="label text-center turquoise-text">Create an Account</p>
    </div>
    <div class="subLabel text-center mb-4">Already have an Account?
        <a
          class="link"
          role="button"
          (click)="
          mainModalService.setModalType(mainModalService.modalTypes.login)
        "
          >Login
        </a>
    </div>
    <div class="col-md-10 col-lg-9 col-12 mx-auto row">
        <div class="col-md-4 col-12">
            <div class="mb-3">
              <label for="firstName" class="form-label">First Name</label>
              <input
                type="text"
                formControlName="firstName"
                class="form-control input-design"
                [ngClass]="{
                  'is-invalid':
                    registerFormCtrl('firstName').errors &&
                    registerFormCtrl('firstName').touched
                }"
                id="firstName"
              />
              <div
                class="invalid-feedback"
                *ngIf="registerFormCtrl('firstName').hasError('required')"
              >
                This field is required!
              </div>
            </div>
          </div>
          <!-- /.col -->
          <div class="col-md-4 col-12">
            <div class="mb-3">
              <label for="lastName" class="form-label">Last Name</label>
              <input
                type="text"
                formControlName="lastName"
                class="form-control input-design"
                [ngClass]="{
                  'is-invalid':
                    registerFormCtrl('lastName').errors &&
                    registerFormCtrl('lastName').touched
                }"
                id="lastName"
              />
              <div
                class="invalid-feedback"
                *ngIf="registerFormCtrl('lastName').hasError('required')"
              >
                This field is required!
              </div>
            </div>
          </div>
          <div class="col-md-4 col-12">
            <div class="mb-3">
              <label for="phoneNumber" class="form-label">Phone Number</label>
              <input
                type="text"
                formControlName="mobileNumber"
                class="form-control input-design"
                [ngClass]="{
                  'is-invalid':
                    registerFormCtrl('mobileNumber').errors &&
                    registerFormCtrl('mobileNumber').touched
                }"
                id="phoneNumber"
              />
              <div class="invalid-feedback" *ngIf="registerFormCtrl('mobileNumber')">
                <p *ngIf="registerFormCtrl('mobileNumber').hasError('required')">
                  This field is required!
                </p>
                <p *ngIf="registerFormCtrl('mobileNumber').hasError('pattern')">
                  Invalid phone number!
                </p>
              </div>
            </div>
          </div>
          <div class="col-md-6 col-12">
            <div class="mb-3">
              <label for="email" class="form-label">Email</label>
              <input
                type="text"
                formControlName="email"
                class="form-control input-design"
                [ngClass]="{
                  'is-invalid':
                    registerFormCtrl('email').errors &&
                    registerFormCtrl('email').touched
                }"
                id="email"
              />
              <div class="invalid-feedback" *ngIf="registerFormCtrl('email')">
                <p *ngIf="registerFormCtrl('email').hasError('required')">
                  This field is required!
                </p>
                <p *ngIf="registerFormCtrl('email').hasError('email')">
                  Invalid email !
                </p>
              </div>
            </div>
          </div>
           <!--Confirm Email-->
           <div class="col-md-6 col-12">
            <div class="mb-4">
              <label for="confirmEmail" class="form-label">Confirm Email</label>
              <input
                type="confirmEmail"
                formControlName="confirmEmail"
                class="form-control input-design"
                [ngClass]="{
                  'is-invalid':
                    registerFormCtrl('confirmEmail').errors &&
                    registerFormCtrl('confirmEmail').touched
                }"
                id="confirmEmail"
                autocomplete="off"
              />
              <div
                class="invalid-feedback"
                *ngIf="registerFormCtrl('confirmEmail')"
              >
                <p *ngIf="registerFormCtrl('confirmEmail').hasError('required')">
                  This field is required!
                </p>
                <p
                  *ngIf="
                    registerFormCtrl('confirmEmail').hasError('emailMismatch')
                  "
                >
                  Email dont match!
                </p>
              </div>
            </div>
          </div>  
          <!--Password-->
          <div class="col-md-6 col-12">
            <div class="mb-4">
              <label for="password" class="form-label">Account Password</label>
              <input
                type="password"
                formControlName="password"
                class="form-control input-design"
                [ngClass]="{
                  'is-invalid':
                    registerFormCtrl('password').errors &&
                    registerFormCtrl('password').touched
                }"
                id="password"
                autocomplete="off"
              />
              <div class="invalid-feedback" *ngIf="registerFormCtrl('password')">
                <p *ngIf="registerFormCtrl('password').hasError('required')">
                  This field is required!
                </p>
                <p *ngIf="registerFormCtrl('password').hasError('pattern')">
                  Atleast 10 characters consist of numbers, uppercase and lowercase
                  letters!
                </p>
              </div>
            </div>
          </div>
          <!--Confirm Password-->
          <div class="col-md-6 col-12">
            <div class="mb-4">
              <label for="confirmPassword" class="form-label">Confirm Password</label>
              <input
                type="password"
                formControlName="confirmPassword"
                class="form-control input-design"
                [ngClass]="{
                  'is-invalid':
                    registerFormCtrl('confirmPassword').errors &&
                    registerFormCtrl('confirmPassword').touched
                }"
                id="confirmPassword"
                autocomplete="off"
              />
              <div
                class="invalid-feedback"
                *ngIf="registerFormCtrl('confirmPassword')"
              >
                <p *ngIf="registerFormCtrl('confirmPassword').hasError('required')">
                  This field is required!
                </p>
                <p
                  *ngIf="
                    registerFormCtrl('confirmPassword').hasError('passwordMismatch')
                  "
                >
                  Password dont match!
                </p>
              </div>
            </div>
          </div>      
          <!-- terms -->
          <div class="col-12">
            <div class="text-center my-3">
              <p class="register-terms turquoise-text">
                By registering, you agree to our
                <a
                  class="turquoise-text"
                  role="button"
                  (click)="openLoginModal(terms)"
                  ><b>Terms & Conditions</b>
                </a>
                and
                <a
                  class="turquoise-text"
                  role="button"
                  data-bs-toggle="modal"
                  (click)="openLoginModal(privacy)"
                  ><b>Privacy Policy</b>
                </a>
              </p>
            </div>
          </div>
          <div class="col-12">
            <div class="d-flex">
              <button
                class="btn orange-btn f-weight-6 align-self-center mx-auto btn-med"
                type="submit"
              [disabled]="!registerForm.valid"
              >
                <div>Register</div>
              </button>
            </div>
          </div>
          <!-- /.col -->
      </div>
</form>
    <!-- /.form -->
<ng-template #terms let-modal>
  <app-terms-and-conditions [modal]="modal"></app-terms-and-conditions>
</ng-template>
<ng-template #privacy let-modal>
  <app-privacy-policy [modal]="modal"></app-privacy-policy>
</ng-template>
