import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AuthService } from '../../services/auth/auth.service';
import { MainModalContainerService } from '../main-modal-container/service/main-modal-container.service';

@Component({
  selector: 'app-logout-form',
  templateUrl: './logout-form.component.html',
  styleUrls: ['./logout-form.component.scss']
})
export class LogoutFormComponent implements OnInit {

  constructor(
    private modalService: NgbModal,
    public auth: AuthService,
    private mainModalService: MainModalContainerService
  ) { }

  ngOnInit(): void {
  }
  dismissModal() {
    // Dismiss the modal
    this.modalService.dismissAll();
  }

}
