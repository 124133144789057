import { Component, OnInit } from '@angular/core';
import { AdsI } from './interface/ads_i';
import { myRouter } from 'src/app/core/route/route-list';
import { ApiHelperService } from 'src/app/core/services/api-helper/api-helper.service';
import { apiUrl } from 'src/app/core/services/api-helper/api_url';
import { ScreenSizeService } from 'src/app/core/services/screen-size/screen-size.service';

@Component({
  selector: 'app-ads',
  templateUrl: './ads.component.html',
  styleUrls: ['./ads.component.scss'],
})
export class AdsComponent implements OnInit {
  adsListWeb: String[] = [];
  adsListMobile: String[] = [];
  AdDetails: any;
  file: any;
  routes = myRouter;
  isMobile: boolean = false;

  constructor(
    private http: ApiHelperService<AdsI[]>,
    public screenUtil: ScreenSizeService
  ) {
    this.isMobile = this.screenUtil.isMobileView;
  }

  ngOnInit(): void {
    this.getAllAds();
  }
  getAllAds() {
    this.http.get({
      url: apiUrl.allAds,
      isAuth: false,
      successCallBack: this.successGetAds,
    });
  }

  successGetAds = (): void => {
    let res = this.http.apiDataState;
    // this.adsListWeb.push(...res.response.result);
    this.initAds(res.response.result);
  };

  initAds(ads: AdsI[]): void {
    ads.forEach((data) => {
      /// old implementation to
      if (
        data.position.toLocaleLowerCase() == 'top' &&
        data.fileUrl.length >= 1
      ) {
        console.log(data.description, data.title, data.fileUrl);
        this.adsListWeb.push(data.fileUrl);
      }

      if (
        data.position.toLocaleLowerCase() == 'top' &&
        data.fileUrl.length <= 0
      ) {
        /// if may mobile implementation to
        if (data.advertisementContents.length >= 1) {
          data.advertisementContents.forEach((content) => {
            if (content.view.toLowerCase() == 'web' && data.fileUrl != null) {
              this.adsListWeb.push(content.fileUrl);
            } // web implementation with mobile

            if (
              content.view.toLowerCase() == 'mobile' &&
              data.fileUrl != null
            ) {
              this.adsListMobile.push(content.fileUrl);
            } /// mobile implementation
          });
        }
      }
    });
  }

  open(ads: any) {
    this.AdDetails = ads;
    window.open('https://' + this.AdDetails.fileUrl);
    return;
  }
}
