import { Component, Input, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { SanitizedUrlService } from '../../service/sanitized-url.service';
import { Router } from '@angular/router';
import { myRouter } from 'src/app/core/route/route-list';

@Component({
  selector: 'app-cash-in-modal',
  templateUrl: './cash-in-modal.component.html',
  styleUrls: ['./cash-in-modal.component.scss'],
})
export class CashInModalComponent implements OnInit {
  @Input() cashInUrl: string = '';
  @Input() modal;
  constructor(
    protected _sanitizer: DomSanitizer,
    private urlService: SanitizedUrlService,
    private router: Router
  ) {}

  ngOnInit(): void {}

  safeUrl() {
    return this._sanitizer.bypassSecurityTrustResourceUrl(
      this.urlService.sanitzedUrl
    );
  }
  refreshPage(): void {
      window.location.reload();
  }
}
