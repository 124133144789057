<div class="row justify-content-center">
  <div class="col-md-8 col-sm-12">
    <div class="card my-4">
      <div class="card-body">
        <form [formGroup]="sendEmailForm" (ngSubmit)="submitSendEmailForm()">
          <div class="row py-4 px-2">
            <div class="col-md-6 col-12">
              <div class="mb-3">
                <label for="firstName" class="form-label">Firstname </label>
                <input
                  class="form-control input-design"
                  id="firstName"
                  aria-describedby="usernameHelp"
                  placeholder="John"
                  autocomplete="off"
                  formControlName="firstName"
                  [ngClass]="{
                    'is-invalid':
                      sendEmailFormCtrl('firstName').errors &&
                      sendEmailFormCtrl('firstName').touched
                  }"
                />
                <div
                  class="invalid-feedback"
                  *ngIf="sendEmailFormCtrl('firstName')"
                >
                  <p
                    *ngIf="sendEmailFormCtrl('firstName').hasError('required')"
                  >
                    This field is required!
                  </p>
                </div>
              </div>
            </div>
            <div class="col-md-6 col-12">
              <div class="mb-3">
                <label for="lastName" class="form-label">Lastname </label>
                <input
                  class="form-control input-design"
                  id="lastName"
                  aria-describedby="usernameHelp"
                  placeholder="Doe"
                  autocomplete="off"
                  formControlName="lastName"
                  [ngClass]="{
                    'is-invalid':
                      sendEmailFormCtrl('lastName').errors &&
                      sendEmailFormCtrl('lastName').touched
                  }"
                />
                <div
                  class="invalid-feedback"
                  *ngIf="sendEmailFormCtrl('lastName')"
                >
                  <p
                    *ngIf="sendEmailFormCtrl('lastName').hasError('required')"
                  >
                    This field is required!
                  </p>
                </div>
              </div>
            </div>
            <div class="col-md-6 col-12">
              <div class="mb-3">
                <label for="email" class="form-label">Email</label>
                <input
                  class="form-control input-design"
                  id="email"
                  aria-describedby="usernameHelp"
                  placeholder="yourEmail@example.com"
                  autocomplete="off"
                  formControlName="email"
                  [ngClass]="{
                    'is-invalid':
                      sendEmailFormCtrl('email').errors &&
                      sendEmailFormCtrl('email').touched
                  }"
                />
                <div
                  class="invalid-feedback"
                  *ngIf="sendEmailFormCtrl('email')"
                >
                  <p
                    *ngIf="sendEmailFormCtrl('email').hasError('required')"
                  >
                    This field is required!
                  </p>
                  <p
                    *ngIf="sendEmailFormCtrl('email').hasError('email')"
                  >
                    Invalid email !
                  </p>
                </div>
              </div>
            </div>
            <!-- /.col -->
            <div class="col-md-6 col-12">
              <div class="mb-3">
                <label for="country" class="form-label">Country </label>
                <input
                  class="form-control input-design"
                  id="country"
                  aria-describedby="usernameHelp"
                  placeholder="Philippines"
                  autocomplete="off"
                  formControlName="country"
                  [ngClass]="{
                    'is-invalid':
                      sendEmailFormCtrl('country').errors &&
                      sendEmailFormCtrl('country').touched
                  }"
                />
                <div
                  class="invalid-feedback"
                  *ngIf="sendEmailFormCtrl('country')"
                >
                  <p *ngIf="sendEmailFormCtrl('country').hasError('required')">
                    This field is required!
                  </p>
                </div>
              </div>
            </div>
            <!-- /.col -->
            <div class="col-md-6 col-12">
              <div class="mb-3">
                <label for="phoneNumber" class="form-label">Phone Number</label>
                <input
                  class="form-control input-design"
                  id="phoneNumber"
                  aria-describedby="usernameHelp"
                  placeholder="11 digit number"
                  autocomplete="off"
                  formControlName="phoneNumber"
                  [ngClass]="{
                    'is-invalid':
                      sendEmailFormCtrl('phoneNumber').errors &&
                      sendEmailFormCtrl('phoneNumber').touched
                  }"
                />
                <div class="invalid-feedback" *ngIf="sendEmailFormCtrl('phoneNumber')">
                  <p *ngIf="sendEmailFormCtrl('phoneNumber').hasError('required')">
                    This field is required!
                  </p>
                  <p *ngIf="sendEmailFormCtrl('phoneNumber').hasError('pattern')">
                    Invalid phone number!
                  </p>
                </div>
              </div>
            </div>
            <div class="col-md-6 col-12">
              <div class="mb-3">
                <label for="subject" class="form-label">Subject </label>
                <input
                  class="form-control input-design"
                  id="subject"
                  aria-describedby="usernameHelp"
                  placeholder="Enter email subject"
                  autocomplete="off"
                  formControlName="subject"
                  [ngClass]="{
                    'is-invalid':
                      sendEmailFormCtrl('subject').errors &&
                      sendEmailFormCtrl('subject').touched
                  }"
                />
                <div
                  class="invalid-feedback"
                  *ngIf="sendEmailFormCtrl('subject')"
                >
                  <p *ngIf="sendEmailFormCtrl('subject').hasError('required')">
                    This field is required!
                  </p>
                </div>
              </div>
            </div>
            <!-- /.col -->
            <div class="col-12">
              <div class="mb-3">
                <label for="message" class="form-label">Message </label>
                <textarea
                  class="form-control input-design"
                  placeholder="Enter your message here."
                  formControlName="message"
                  [ngClass]="{
                    'is-invalid':
                      sendEmailFormCtrl('message').errors &&
                      sendEmailFormCtrl('message').touched
                  }"
                ></textarea>
                <div
                  class="invalid-feedback"
                  *ngIf="sendEmailFormCtrl('message')"
                >
                  <p *ngIf="sendEmailFormCtrl('message').hasError('required')">
                    This field is required!
                  </p>
                </div>
              </div>
            </div>
            <!-- /.textarea -->
            <div class="col-12 mb-3 justify-content text-center">
              <div class="d-flex">
                <button
                  class="btn orange-btn f-weight-6 align-self-center mx-auto btn-med"
                  type="submit"
                  [disabled]="!sendEmailForm.valid"
                >
                  SEND
                </button>
              </div>
            </div>
          </div>
          <!-- /.row -->
        </form>
      </div>
      <!-- /.card-body -->
    </div>
    <!-- /.card -->
  </div>
  <!-- /.col -->
</div>
