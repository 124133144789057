import { Component, OnInit } from '@angular/core';
import { SendEmailResponseInterface } from './interface/send_email_response_interface';
import { ApiHelperService } from 'src/app/core/services/api-helper/api-helper.service';
import { AbstractControl, FormBuilder, Validators } from '@angular/forms';
import { apiUrl } from 'src/app/core/services/api-helper/api_url';

@Component({
  selector: 'app-contact-us',
  templateUrl: './contact-us.component.html',
  styleUrls: ['./contact-us.component.scss'],
})
export class ContactUsComponent implements OnInit {
  static readonly routeName: string = 'contact-us';
  // form input builder
  sendEmailForm = this.formBuilder.group({
    firstName: ['', Validators.required],
    lastName: ['', Validators.required],
    email: ['', [Validators.required, Validators.email]],
    country: ['', Validators.required],
    phoneNumber: [
      '',
      [Validators.required, Validators.pattern(/^[0-9]{11}$/)],
    ],
    subject: ['', Validators.required],
    message: ['', Validators.required],
  });

  constructor(
    private http: ApiHelperService<SendEmailResponseInterface>,
    private formBuilder: FormBuilder
  ) {}

  ngOnInit(): void {}
  get sendEmailControl() {
    return this.sendEmailForm.controls;
  }
  sendEmailFormCtrl(name: string): AbstractControl {
    return this.sendEmailForm.get(name);
  }

  submitSendEmailForm() {
    this.http.post({
      url: apiUrl.sendEmailInquire,
      data: this.sendEmailForm.value,
      isAuth: false,
      successCallBack: this.callback,
    });
  }

  callback = (): void => {
    let response = this.http.apiDataState.response;
    console.log(response);
    this.sendEmailForm.reset();
  };
}
