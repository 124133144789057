<div class="share">
  <div class="col-12 text-center">
    <!-- {{sample(1)}} -->
    <i
      (click)="shareFacebook()"
      class="fa fa-facebook-square footer-icon"
      aria-hidden="true"
    ></i>
    <i
      (click)="shareTwitter()"
      class="fa fa-twitter-square footer-icon"
      aria-hidden="true"
    ></i>
    <!-- <i class="fa fa-youtube-play footer-icon" aria-hidden="true"></i>
      <i class="fa fa-instagram footer-icon"></i>
      <i class="fa fa-linkedin-square footer-icon"></i> -->
  </div>
</div>
