<div class="row justify-content-center mb-3">
    <div class="col-10 text-center">
      <p class="my-4 white-text">
        <b>Are you sure you want to <br />
        logout?</b>
      </p>
    </div>
    <div class="col-10 mb-3 justify-content text-center">
      <button
        class="btn orange-btn f-weight-6 align-self-center m-2 w-150"
        type="button"
        (click)="auth.logoutUser()"
      >
        Yes
      </button>
      <button
        class="btn orange-btn f-weight-6 align-self-center m-2 w-150"
        type="button"
        (click)="dismissModal()"
      >
        No
      </button>
    </div>
    <!-- /.col -->
  </div>